import React, { Dispatch, SetStateAction } from "react"
import { Button, Typography } from "@material-ui/core"
import { createStyles, makeStyles } from "@material-ui/core"
import { Snippet } from "@components/Snippet"

export const LOGIN_AND_CONFIG_CLI = "LOGIN_AND_CONFIG_CLI"

type LoginConfigureCLIProps = {
  orgID: string
  currentStep: string
  setStep: Dispatch<SetStateAction<number>>
}

const useStyles = makeStyles(() =>
  createStyles({
    disabled: {
      pointerEvents: "none",
      opacity: 0.6,
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
  }),
)

export const LoginConfigureCLI = ({ orgID, setStep, currentStep }: LoginConfigureCLIProps) => {
  const isCurrentStep = currentStep === LOGIN_AND_CONFIG_CLI
  const classes = useStyles()
  return (
    <div className={!isCurrentStep ? classes.disabled : ""}>
      <Typography variant="h5" gutterBottom>
        Step 2. Login and Configure the CLI
      </Typography>

      {isCurrentStep && (
        <div>
          <Typography color="textSecondary" gutterBottom>
            Now that you have <code>jsctl</code> installed we need to login to Jetstack Secure and set the current organization to&ensp;
            <code>{orgID}</code>.
          </Typography>
          <br />
          <Snippet
            text={`jsctl auth login
$ jsctl config set organization ${orgID}`}
            copyText={`jsctl auth login
jsctl config set organization ${orgID}`}
            language="shell"
          />
          <div className={classes.buttonWrapper}>
            <Button variant="contained" color="primary" disabled={false} onClick={() => setStep(step => step + 1)}>
              Next
            </Button>
            <Button variant="contained" color="secondary" disabled={false} onClick={() => setStep(step => step - 1)}>
              Back
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
