import React, { useState } from "react"
import { truncate } from "lodash"
import { useDispatch } from "react-redux"
import { useParams } from "react-router"
import { Button, createStyles, makeStyles, Theme, Typography, Switch } from "@material-ui/core"

import { getOrgID } from "@routes"
import { updateReceiver } from "@actions/receivers"
import { getReceiverConfig, getReceiverType, extractReceiverUrl } from "../helpers"
import { ConfigForm } from "../ConfigForm"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `2px solid ${theme.palette.grey[300]}`,
      borderRadius: "5px",
      padding: theme.spacing(1.5, 1.5, 1.5, 2),
      marginTop: theme.spacing(2),
    },
    isEnabled: {
      borderColor: theme.palette.success.main,
    },
    input: {
      width: "100%",
      margin: theme.spacing(2, 0),
    },
    title: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
    topBar: {
      display: "flex",
      justifyContent: "space-between",
    },
    buttons: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
    disableButton: {
      background: theme.palette.error.main,
    },
    content: {
      marginTop: theme.spacing(2),
      paddingRight: theme.spacing(0.5),
    },
    titleContent: {},
    name: {
      fontSize: "1rem",
      textTransform: "uppercase",
      lineHeight: 1,
    },
    webhook: {
      fontSize: "0.75rem",
      opacity: 0.6,
      lineHeight: 1,
    },
    icon: {
      margin: theme.spacing(1.1),
      transform: "scale(1.7)",
    },
    isSuccess: {
      color: theme.palette.success.dark,
      fontSize: "0.875rem",
    },
    isError: {
      color: theme.palette.error.main,
      fontSize: "0.875rem",
    },
  }),
)

type Props = {
  receiver: Receiver
  isAdmin?: boolean
  cluster: string
}

export function ReceiverRow({ receiver, isAdmin, cluster }: Props) {
  const classes = useStyles()
  const params = useParams<{ orgId: string; clusterID: string; clusterView: string }>()
  const organization = getOrgID(params)
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)

  const receiverType = getReceiverType(receiver)

  const truncatedUrl = isAdmin
    ? truncate(extractReceiverUrl(receiver)?.replace("https://", ""), {
        length: 34,
        omission: "...",
      })
    : "***********************"

  const handleUpdate = (isEnabled: boolean) => {
    const enableSettings =
      receiverType === "slack"
        ? {
            slack_webhook_enabled: isEnabled,
          }
        : {
            microsoft_teams_webhook_enabled: isEnabled,
          }
    dispatch(
      updateReceiver(organization, cluster, {
        ...receiver,
        ...enableSettings,
      }),
    )
  }

  const isEnabled = receiver.slack_webhook_enabled || receiver.microsoft_teams_webhook_enabled
  return (
    <div className={`${classes.root} ${isEnabled ? classes.isEnabled : ""}`}>
      <div className={classes.topBar}>
        <div className={classes.title}>
          <div className={classes.icon}>{getReceiverConfig(receiverType)?.icon}</div>
          <div className={classes.titleContent}>
            <Typography variant="h6" className={classes.name}>
              {receiver.display_name}
            </Typography>
            <Typography variant="caption" className={classes.webhook} display="block">
              <strong>Include warnings:</strong>{" "}
              <span className={receiver.send_warnings ? classes.isSuccess : classes.isError}>{receiver.send_warnings ? "Yes" : "No"}</span>
            </Typography>
            <Typography variant="caption" className={classes.webhook} display="block">
              <strong>Include system certificates:</strong>{" "}
              <span className={receiver.alert_on_system_certificates ? classes.isSuccess : classes.isError}>
                {receiver.alert_on_system_certificates ? "Yes" : "No"}
              </span>
            </Typography>
            <Typography variant="caption" className={classes.webhook}>
              <strong>Url:</strong> {truncatedUrl}
            </Typography>
          </div>
        </div>
        <div className={classes.buttons}>
          <Button variant="contained" size="small" color="primary" onClick={() => setIsOpen(state => !state)} disabled={!isAdmin}>
            Edit
          </Button>
          <Switch checked={isEnabled} onChange={event => handleUpdate(event.target.checked)} color="primary" disabled={!isAdmin} />
        </div>
      </div>
      {isOpen && (
        <div className={classes.content}>
          <ConfigForm type={receiverType} onCancel={() => setIsOpen(false)} receiver={receiver} cluster={cluster} />
        </div>
      )}
    </div>
  )
}
