import React, { Dispatch, SetStateAction } from "react"
import { Button, Typography, createStyles, makeStyles } from "@material-ui/core"

import { Snippet } from "@components/Snippet"

const useStyles = makeStyles(() =>
  createStyles({
    disabled: {
      pointerEvents: "none",
      opacity: 0.6,
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
  }),
)

type DeployOperatorProps = {
  currentStep: string
  setStep: Dispatch<SetStateAction<number>>
}

export const DEPLOY_OPERATOR = "DEPLOY_OPERATOR"

export const DeployOperator = ({ currentStep, setStep }: DeployOperatorProps) => {
  const classes = useStyles()
  const isCurrentStep = currentStep === DEPLOY_OPERATOR
  return (
    <div className={!isCurrentStep ? classes.disabled : ""}>
      <Typography variant="h5" gutterBottom>
        Step 5. Prepare for Component Installation
      </Typography>

      {isCurrentStep && (
        <div>
          <Typography color="textSecondary" gutterBottom>
            To help you get up and running with all that Jetstack Secure has to offer, we have created a Kubernetes operator to install and
            configure the components of the platform. Using the operator it is possible to install all other components.
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            To deploy the operator, run the following command:
          </Typography>
          <br />
          <Snippet text={`jsctl operator deploy --auto-registry-credentials`} language="shell" />
          <div className={classes.buttonWrapper}>
            <Button variant="contained" color="primary" disabled={false} onClick={() => setStep(step => step + 1)}>
              Next
            </Button>
            <Button variant="contained" color="secondary" disabled={false} onClick={() => setStep(step => step - 1)}>
              Back
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
