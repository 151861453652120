import React from "react"
import { Typography, makeStyles, createStyles, Theme } from "@material-ui/core"

import { Issuer } from "@lib/certInventory/resources"
import * as cm from "@lib/certInventory/cert-manager"
import * as external from "@lib/certInventory/external"

import { MessageContainer } from "@components/MessageContainer"
import { ListIcon } from "@components/Icons"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      marginBottom: theme.spacing(2),
    },
    inner: {
      marginBottom: theme.spacing(1),
    },
  }),
)

type Props = {
  issuer?: Issuer
}

const renderMessageContainer = (name: string, value: string) => {
  return (
    <MessageContainer icon={<ListIcon />}>
      {`${name} > `}
      <Typography color="textPrimary" variant="body2" display="inline">
        {value}
      </Typography>
    </MessageContainer>
  )
}

export function IssuerInfo({ issuer }: Props) {
  const classes = useStyles()

  if (!issuer) {
    return <Typography>No details were found.</Typography>
  }

  const renderGoogleCasSpec = (spec: external.GoogleCasSpec) => {
    return (
      <div className={classes.section}>
        <Typography gutterBottom>Google CAS Info</Typography>
        {spec.caPoolId && (
          <>
            <Typography gutterBottom>Certificate Authority Pool Id</Typography>
            <div className={classes.inner}>{renderMessageContainer("Id", spec.caPoolId)}</div>
          </>
        )}
        {(spec.location || spec.project || spec.credentials) && (
          <>
            <Typography gutterBottom>Google Cloud Project Info</Typography>
            <div className={classes.inner}>
              {spec.project && renderMessageContainer("Id", spec.project)}
              {spec.location && renderMessageContainer("Location", spec.location)}
              {spec.credentials?.name && renderMessageContainer("Service Account", spec.credentials.name)}
            </div>
          </>
        )}
      </div>
    )
  }

  const renderAwsPcaSpec = (spec: external.AwsPcaSpec) => {
    return (
      <div className={classes.section}>
        <Typography gutterBottom>AWS Private Certificate Authority Info</Typography>
        {(spec.region || spec.secretRef) && (
          <div className={classes.inner}>
            {spec.arn && renderMessageContainer("Arn", spec.arn)}
            {spec.region && renderMessageContainer("Region", spec.region)}
            {spec.secretRef?.name && renderMessageContainer("Secret", spec.secretRef.name)}
          </div>
        )}
      </div>
    )
  }

  const renderAcmeSpec = (acme: cm.ACMEIssuerSpec) => {
    return (
      <div className={classes.section}>
        <Typography gutterBottom>ACME Info</Typography>
        <div className={classes.inner}>
          {acme.email && renderMessageContainer("Email", acme.email)}
          {acme.privateKeySecretRef && renderMessageContainer("Secret", acme.privateKeySecretRef.name)}
          {acme.server && renderMessageContainer("Server", acme.server)}
          {acme.preferredChain && renderMessageContainer("Preferred Chain", acme.preferredChain)}
        </div>
      </div>
    )
  }

  const renderVaultSpec = (vault: cm.VaultIssuerSpec) => {
    return (
      <div className={classes.section}>
        <Typography gutterBottom>Vault Info</Typography>
        <div className={classes.inner}>
          {vault.namespace && renderMessageContainer("Namespace", vault.namespace)}
          {vault.server && renderMessageContainer("Server", vault.server)}
          {vault.path && renderMessageContainer("Path", vault.path)}
        </div>
      </div>
    )
  }

  const renderVenafiSpec = (venafi: cm.VenafiIssuerSpec) => {
    return (
      <div className={classes.section}>
        <Typography gutterBottom>Venafi Info</Typography>
        <div className={classes.inner}>
          {venafi.zone && renderMessageContainer("Zone", venafi.zone)}
          {venafi.tpp && (
            <>
              <Typography gutterBottom>Venafi TPP</Typography>
              <div className={classes.inner}>
                {renderMessageContainer("Url", venafi.tpp.url)}
                {renderMessageContainer("Credentials", venafi.tpp.credentialsRef.name)}
              </div>
            </>
          )}
          {venafi.cloud && (
            <>
              <Typography gutterBottom>Venafi Cloud</Typography>
              <div className={classes.inner}>
                {venafi.cloud.url && renderMessageContainer("Url", venafi.cloud.url)}
                {renderMessageContainer("API Secret", venafi.cloud.apiTokenSecretRef.name)}
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  const renderCaSpec = (ca: cm.CAIssuerSpec) => {
    return (
      <div className={classes.section}>
        <Typography gutterBottom>CA Info</Typography>
        <div className={classes.inner}>{ca.secretName && renderMessageContainer("CA Secret", ca.secretName)}</div>
      </div>
    )
  }

  const renderSelfSignedSpec = () => {
    return (
      <div className={classes.section}>
        <Typography gutterBottom>Self Signed Info</Typography>
        <div className={classes.inner}>
          <MessageContainer icon={<ListIcon />}>
            <Typography color="textPrimary" variant="body2" display="inline">
              Self Signed
            </Typography>
          </MessageContainer>
        </div>
      </div>
    )
  }

  // Check if the issuer is external and render the according details
  switch (issuer.issuerType) {
    case external.IssuerTypes.GoogleCas:
      if (issuer.resource.spec) {
        const issuerSpec = issuer.resource.spec as external.GoogleCasSpec
        return <div>{renderGoogleCasSpec(issuerSpec)}</div>
      }
      break
    case external.IssuerTypes.AwsPca:
      if (issuer.resource.spec) {
        const issuerSpec = issuer.resource.spec as external.AwsPcaSpec
        return <div>{renderAwsPcaSpec(issuerSpec)}</div>
      }
      break
  }

  if (issuer.resource.spec) {
    const issuerSpec = issuer.resource.spec as cm.IssuerSpec
    if (issuerSpec.acme) {
      // ACME spec
      return <div>{renderAcmeSpec(issuerSpec.acme)}</div>
    }
    if (issuerSpec.vault) {
      // Vault spec
      return <div>{renderVaultSpec(issuerSpec.vault)}</div>
    }
    if (issuerSpec.venafi) {
      // Vanafi spec
      return <div>{renderVenafiSpec(issuerSpec.venafi)}</div>
    }
    if (issuerSpec.ca) {
      // ca spec
      return <div>{renderCaSpec(issuerSpec.ca)}</div>
    }
    if (issuerSpec.selfSigned) {
      // selfSigned spec
      return <div>{renderSelfSignedSpec()}</div>
    }
  }

  return null
}
