import React, { ReactElement } from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import { Typography, Tooltip, Chip } from "@material-ui/core"
import { formatDistanceToNow } from "date-fns"

import { InstallationComponent } from "@lib/certInventory"

import { KubernetesIcon, OpenshiftIcon } from "@components/Icons"
import { StatusIndicator } from "@components/StatusIndicator"
import { UpdateAvailable } from "@components/CertInventory/AddonInstallationStatusCompact/UpdateAvailable"
import { OverviewCluster } from "@components/CertInventoryMultiCluster/hooks"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      cursor: "pointer",
      transition: "all 0.3s ease-in-out",
      borderLeft: `3px solid transparent`,
      fontSize: "0.875rem",
      lineHeight: 1.3,
      "&:hover": {
        borderLeft: `3px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.background.default,
        boxShadow: `0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)`,
      },
      "& td": {
        padding: theme.spacing(2, 1.5),
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        verticalAlign: "middle",
      },
    },
    status: {
      textAlign: "center",
      [theme.breakpoints.down(1024)]: {
        whiteSpace: "nowrap",
      },
      "& > div": {
        marginRight: theme.spacing(1),
        "&:last-child": {
          marginRight: 0,
        },
      },
    },
    noCerts: {
      fontSize: "0.875rem",
      color: theme.palette.text.secondary,
    },
    title: {
      color: theme.palette.text.primary,
      [theme.breakpoints.down(1024)]: {
        whiteSpace: "nowrap",
      },

      "& em span": {
        borderBottom: `1px dashed ${theme.palette.text.primary}`,
      },
    },
    icon: {
      marginRight: theme.spacing(1.25),
      "& svg": {
        marginBottom: "-6px",
      },
    },
    certManagerStatus: {
      whiteSpace: "nowrap",
      marginRight: theme.spacing(1),
    },
    demoChip: {
      marginLeft: theme.spacing(2),
      lineHeight: 1,
    },
    expired: {
      "& td": {
        opacity: 0.5,
      },
    },
  }),
)

type ClusterRowProps = {
  cluster: OverviewCluster
  onClick: (cluster: string) => void
}

export function ClusterRow({ cluster, onClick }: ClusterRowProps): ReactElement {
  const classes = useStyles()
  const handleClick = () => {
    onClick(cluster.id)
  }

  const { controller, cainjector, webhook } = cluster.certManagerStatus
  const items = [
    ["Controller", controller],
    ["CA Injector", cainjector],
    ["Webhook", webhook],
  ] as [string, InstallationComponent][]

  const updateLink = "https://cert-manager.io/docs/installation/upgrading/"
  const isOpenShift = cluster.clusterType === "openshift"
  const date = new Date(cluster.agentLastUploadTime)
  const lastUpdated = cluster.agentLastUploadTime ? formatDistanceToNow(date, { includeSeconds: true }) : ""

  return (
    <>
      <tr className={`${classes.row} ${!cluster.active ? classes.expired : ""}`} onClick={handleClick} data-testid="cluster-row">
        <td className={classes.icon}>{isOpenShift ? <OpenshiftIcon /> : <KubernetesIcon />}</td>
        <td className={classes.title}>
          <Typography variant="body1" display="inline" data-testid="cluster-row-title" className="ClusterName">
            {cluster.id}{" "}
            {!cluster.active && (
              <Tooltip
                title={<Typography variant="body2">The cluster has not uploaded any data in the last 60 minutes.</Typography>}
                className={classes.demoChip}
              >
                <em>
                  ( <span>inactive</span> )
                </em>
              </Tooltip>
            )}
          </Typography>
          {cluster.isDemo && (
            <Tooltip title="This is not a real cluster and it's intended for demo purposes" className={classes.demoChip}>
              <Chip label="demo" variant="outlined" size="small" />
            </Tooltip>
          )}
        </td>

        <td className={classes.certManagerStatus}>
          <StatusIndicator
            status={cluster.certManagerIssues ? "error" : "ok"}
            message={cluster.certManagerIssues ? "Has issues" : "All ready"}
            iconsOnly
          />
        </td>
        <td className={classes.certManagerStatus}>
          <UpdateAvailable items={items} updateLink={updateLink} />
        </td>
        <td>
          {cluster.agentLastUploadTime ? <abbr title="Last time the agent contacted Jetstack Secure.">{lastUpdated} ago</abbr> : "Never"}
        </td>
        <td className={classes.status}>
          {cluster.certificateCount < 1 ? (
            <span className={classes.noCerts}>No certificates</span>
          ) : (
            <>
              {cluster.errors > 0 && <StatusIndicator status="error" value={cluster.errors} />}
              {cluster.warnings > 0 && <StatusIndicator status="warning" value={cluster.warnings} />}
              {cluster.warnings < 1 && cluster.errors < 1 && <StatusIndicator status="ok" />}
            </>
          )}
        </td>
      </tr>
    </>
  )
}
