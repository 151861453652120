import React, { useState, ChangeEvent } from "react"
import { createStyles, makeStyles, Theme as MUITheme, Box, Tab, Tabs } from "@material-ui/core"
import { TabContext, TabPanel } from "@material-ui/lab"

import { Snippet } from "@components/Snippet"
import { DownloadFile } from "@components/DownloadFile"
import { Step } from "../Step"

const useStyles = makeStyles((theme: MUITheme) =>
  createStyles({
    box: {
      margin: theme.spacing(2, 0),
      width: "100%",
    },
    tabs: {
      borderBottom: "1px solid rgba(0,0,0,0.12)",
    },
  }),
)

type PullSecretInstallProps = {
  b64Dockercfg: string
  name: string
}

export const PullSecretInstall = ({ b64Dockercfg, name }: PullSecretInstallProps) => {
  const classes = useStyles()
  const [value, setValue] = useState("k8s")

  const dockercfg = Buffer.from(b64Dockercfg, "base64").toString("binary")

  const handleChange = (event: ChangeEvent<unknown>, newValue: string) => {
    setValue(newValue)
  }

  const keyTabContent = (
    <>
      <Snippet text={JSON.stringify(JSON.parse(dockercfg), null, 2)} language="json" />
      <DownloadFile text="Download dockercfg" type="text/json" filename={`${name}-docker-config.json`} content={dockercfg} />
    </>
  )

  return (
    <Box className={classes.box}>
      <TabContext value={value}>
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          aria-label="Image pull secret installation instructions"
          indicatorColor="primary"
        >
          <Tab label="Kubernetes" value="k8s" />
          <Tab label="OpenShift" value="openshift" />
          <Tab label="Download dockercfg" value="dockercfg" />
        </Tabs>
        <TabPanel value="k8s">
          <InstallSteps platform="k8s" b64Dockercfg={b64Dockercfg} name={name} />
        </TabPanel>
        <TabPanel value="openshift">
          <InstallSteps platform="openshift" b64Dockercfg={b64Dockercfg} name={name} />
        </TabPanel>
        <TabPanel value="dockercfg">{keyTabContent}</TabPanel>
      </TabContext>
    </Box>
  )
}

type InstallStepsProps = {
  platform: "openshift" | "k8s"
  b64Dockercfg: string
  name: string
}
const InstallSteps = ({ platform, b64Dockercfg }: InstallStepsProps) => {
  const platformName = {
    openshift: "OpenShift",
    k8s: "Kubernetes",
  }

  const platformTool = {
    openshift: "oc",
    k8s: "kubectl",
  }

  const secretName = "jetstack-registry-pull"

  const createSecretCommand = `${platformTool[platform]} create --namespace=default -f - <<EOF
apiVersion: v1
kind: Secret
metadata:
  name: ${secretName}
data:
  .dockerconfigjson: ${b64Dockercfg}
type: kubernetes.io/dockerconfigjson
EOF`

  const patchSvcAccountCommand = `${platformTool[platform]} patch --namespace=default serviceaccount default -p '{"imagePullSecrets": [{"name": "${secretName}"}]}'`

  return (
    <div>
      <Step number={1} title={`Apply this command to create a secret in ${platformName[platform]}`}>
        <Snippet text={createSecretCommand} language="shell" />
      </Step>
      <Step
        number={2}
        title={`Patch the default ${platformName[platform]} service account so it uses the image pull secret. This will enable all workloads in the namespace to use the image pull secret.`}
      >
        <Snippet text={patchSvcAccountCommand} language="shell" />
      </Step>
    </div>
  )
}
