import { Params } from "react-router-dom"

const ORG_URL_PARAM = "orgID"
const CLUSTER_URL_PARAM = "clusterID"
const CLUSTER_VIEW_PARAM = "clusterView"
const MANAGE_VIEW_PARAM = "manageView"

export const getOrgID = (params: Params) => {
  return params[ORG_URL_PARAM] || ""
}

export const getClusterID = (params: Params) => {
  return params[CLUSTER_URL_PARAM] || ""
}

export const getClusterView = (params: Params) => {
  return params[CLUSTER_VIEW_PARAM] || ""
}

export const DOCS_PATH = "https://platform.jetstack.io/documentation"
export const HOME_PATH = "/"
export const LOGIN_PATH = "/login"
export const USER_PROFILE_PATH = "/profile"
export const MANAGE_TEAM_PATH = "/manage-team"
export const TOS_PATH = "/tos"
export const FORCE_ERROR_PATH = "/forceAnError"
export const AWS_LANDING = "/aws_landing"
export const AWS_LINK_ORGANIZATION = "/aws_link_organization"
export const SUPPORT_PATH = "/support"

// New Cluster
export const pathToNewCluster = (orgID: string) => {
  return `/org/${orgID}/new-cluster`
}

// New Cluster V2
export const pathToConnectCluster = (orgID: string) => {
  return `/org/${orgID}/connect-cluster`
}

// New Issuer
export const pathToNewIssuer = (orgID: string) => {
  return `/org/${orgID}/new-issuer`
}

// Update Agent
export const pathToUpdateAgent = (orgID: string, clusterID: string) => {
  return `/org/${orgID}/cluster/${clusterID}/update-agent`
}

// Organization Overview
export const pathToOverview = (orgID: string) => {
  return `/org/${orgID}/overview`
}

// Organization Overview
export const pathToSummaries = (orgID: string) => {
  return `/org/${orgID}/summaries`
}

export const pathToAllResources = (orgID: string, view: string) => {
  return `/org/${orgID}/resources/${view}`
}
// Organization settings
export const pathToSettings = (orgID: string, view: string) => {
  return `/org/${orgID}/manage/${view}`
}
// Organization root
export const pathToRoot = (orgID: string) => {
  return `/org/${orgID}/`
}

// CertInventory
export const pathToCertInventory = (orgID: string): string => {
  return `/org/${orgID}/certinventory`
}
// the view prop refers to which tab is selected when showing cluster details
export const pathToCertInventoryCluster = (orgID: string, clusterID: string, view: string) => {
  return `/org/${orgID}/certinventory/cluster/${clusterID}/${view}`
}

export const templatePaths = {
  newCluster: pathToNewCluster(`:${ORG_URL_PARAM}`),
  newClusterV2: pathToConnectCluster(`:${ORG_URL_PARAM}`),
  newIssuer: pathToNewIssuer(`:${ORG_URL_PARAM}`),
  updateAgent: pathToUpdateAgent(`:${ORG_URL_PARAM}`, `:${CLUSTER_URL_PARAM}`),
  certInventory: pathToCertInventory(`:${ORG_URL_PARAM}`),
  orgOverview: pathToOverview(`:${ORG_URL_PARAM}`),
  orgSummaries: pathToSummaries(`:${ORG_URL_PARAM}`),
  orgSettings: pathToSettings(`:${ORG_URL_PARAM}`, `:${MANAGE_VIEW_PARAM}?`),
  orgRoot: pathToRoot(`:${ORG_URL_PARAM}`),
  allResources: pathToAllResources(`:${ORG_URL_PARAM}`, `:${CLUSTER_VIEW_PARAM}?`),
  certInventoryCluster: pathToCertInventoryCluster(`:${ORG_URL_PARAM}`, `:${CLUSTER_URL_PARAM}`, `:${CLUSTER_VIEW_PARAM}?`),
}

export const routePaths = {
  orgRoot: pathToRoot(`:${ORG_URL_PARAM}`),
  newCluster: pathToNewCluster(`:${ORG_URL_PARAM}`),
  newClusterV2: pathToConnectCluster(`:${ORG_URL_PARAM}`),
  newIssuer: pathToNewIssuer(`:${ORG_URL_PARAM}`),
  updateAgent: `/org/:${ORG_URL_PARAM}/cluster/:${CLUSTER_URL_PARAM}/update-agent`,
  certInventory: `/org/:${ORG_URL_PARAM}/certinventory`,
  certInventoryCluster: `/org/:${ORG_URL_PARAM}/certinventory/cluster/:${CLUSTER_URL_PARAM}`,
  certInventoryClusterParam: `/org/:${ORG_URL_PARAM}/certinventory/cluster/:${CLUSTER_URL_PARAM}/:${CLUSTER_VIEW_PARAM}`,
  orgOverview: pathToOverview(`:${ORG_URL_PARAM}`),
  orgSummaries: pathToSummaries(`:${ORG_URL_PARAM}`),
  orgSettings: `/org/:${ORG_URL_PARAM}/manage/:${MANAGE_VIEW_PARAM}`,
  allResources: `/org/:${ORG_URL_PARAM}/resources/:${CLUSTER_VIEW_PARAM}`,
}
