import { takeEvery, call, select, put } from "redux-saga/effects"
import axios from "axios"
import { AxiosError } from "axios"

import {
  CREATE_RECEIVER,
  FETCH_RECEIVERS,
  UPDATE_RECEIVER,
  CreateReceiverAction,
  createReceiverSuccess,
  createReceiverFailed,
  UpdateReceiverAction,
  updateReceiverSuccess,
  updateReceiverFailed,
  FetchReceiversAction,
  fetchReceiversSuccess,
  fetchReceiversFailed,
  DELETE_RECEIVER,
  deleteReceiverSuccess,
  deleteReceiverFailed,
  DeleteReceiverAction,
} from "../actions/receivers"

import { accessTokenSelector } from "../selectors/auth"
import { generateErrorMessage, handleError } from "@utils/errorHandling"
import { addAuth } from "@lib/auth"

function createReceiver(accessToken: string, receiver: Receiver) {
  return axios
    .post(`/api/v1/org/${receiver.organization}/clusters/${receiver.cluster}/receivers`, [receiver], addAuth(accessToken))
    .catch((error: AxiosError) => {
      if (error.request) {
        handleError(error, "request error")
      } else {
        handleError(error, "axios error")
      }
    })
}
function* createReceiverSaga(action: CreateReceiverAction) {
  try {
    const token: string = yield select(accessTokenSelector)
    const { data } = yield call(createReceiver, token, action.receiver)

    if (data.length > 0) {
      yield put(createReceiverSuccess(action.orgID, action.clusterID, data[0] as Receiver))
    } else {
      yield put(createReceiverFailed(action.orgID, action.clusterID, `Unable to create alert receiver - missing data in response`))
    }
  } catch (error) {
    handleError(error, `Unable to create alert receiver`)
    yield put(createReceiverFailed(action.orgID, action.clusterID, `Unable to create alert receiver - ${generateErrorMessage(error)}`))
  }
}

function updateReceiver(accessToken: string, receiver: Receiver) {
  return axios
    .patch(`/api/v1/org/${receiver.organization}/clusters/${receiver.cluster}/receivers/${receiver.id}`, receiver, addAuth(accessToken))
    .catch((error: AxiosError) => {
      if (error.request) {
        handleError(error, "request error")
      } else {
        handleError(error, "axios error")
      }
    })
}
function* updateReceiverSaga(action: UpdateReceiverAction) {
  try {
    const token: string = yield select(accessTokenSelector)
    const { data } = yield call(updateReceiver, token, action.receiver)

    if (data as Receiver) {
      yield put(updateReceiverSuccess(action.orgID, action.clusterID, data))
    } else {
      yield put(updateReceiverFailed(action.orgID, action.clusterID, `Unable to update alert receiver - missing data in response`))
    }
  } catch (error) {
    handleError(error, `Unable to update alert receiver`)
    yield put(updateReceiverFailed(action.orgID, action.clusterID, `Unable to update alert receiver - ${generateErrorMessage(error)}`))
  }
}

function deleteReceiver(accessToken: string, actionData: DeleteReceiverAction) {
  return axios
    .delete(`/api/v1/org/${actionData.orgID}/clusters/${actionData.clusterID}/receivers/${actionData.receiverID}`, addAuth(accessToken))
    .catch((error: AxiosError) => {
      if (error.request) {
        handleError(error, "request error")
      } else {
        handleError(error, "axios error")
      }
    })
}
function* deleteReceiverSaga(action: DeleteReceiverAction) {
  try {
    const token: string = yield select(accessTokenSelector)
    const { data } = yield call(deleteReceiver, token, action)

    if (data as Receiver) {
      yield put(deleteReceiverSuccess(action.orgID, action.clusterID, data))
    } else {
      yield put(deleteReceiverFailed(action.orgID, action.clusterID, `Unable to delete alert receiver - missing data in response`))
    }
  } catch (error) {
    handleError(error, `Unable to delete alert receiver`)
    yield put(deleteReceiverFailed(action.orgID, action.clusterID, `Unable to delete alert receiver - ${generateErrorMessage(error)}`))
  }
}

function fetchReceivers(accessToken: string, orgID: string, clusterID: string) {
  const data = axios.get(`/api/v1/org/${orgID}/clusters/${clusterID}/receivers`, addAuth(accessToken)).catch((error: AxiosError) => {
    if (error.request) {
      handleError(error, "request error")
    } else {
      handleError(error, "axios error")
    }
  })

  return data
}
function* fetchReceiversSaga(action: FetchReceiversAction) {
  try {
    // @ts-expect-error: TODO: Fix this
    const token = yield select(accessTokenSelector)
    const { data } = yield call(fetchReceivers, token, action.orgID, action.clusterID)
    yield put(fetchReceiversSuccess(action.orgID, action.clusterID, data))
  } catch (error) {
    handleError(error, `Unable to fetch cluster's alert receivers`)
    yield put(
      fetchReceiversFailed(action.orgID, action.clusterID, `Unable to fetch cluster's alert receivers- ${generateErrorMessage(error)}`),
    )
  }
}

export function* receiverSaga() {
  yield takeEvery(CREATE_RECEIVER, createReceiverSaga)
  yield takeEvery(FETCH_RECEIVERS, fetchReceiversSaga)
  yield takeEvery(UPDATE_RECEIVER, updateReceiverSaga)
  yield takeEvery(DELETE_RECEIVER, deleteReceiverSaga)
}
