import axios from "axios"
import { useQuery, useQueries } from "@tanstack/react-query"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { getOrgID } from "@routes"
import { accessTokenSelector } from "@selectors/auth"
import { addAuth } from "@lib/auth"
import { ClusterData } from "@lib/certInventory"
import { handleError } from "@utils/errorHandling"
import { OverviewAxiosResponse } from "./types"
import { QUERYKEYS } from "@lib/queryKeys"
import { useMemo } from "react"

// query overview data for organization
export function useGetOverviewStats(isDemo?: boolean) {
  const orgID = getOrgID(useParams())
  const token = useSelector(accessTokenSelector)

  const { data, isLoading, isSuccess, error } = useQuery<OverviewAxiosResponse>(
    QUERYKEYS.overview.all(orgID),
    () => axios.get(`/api/v1/org/${orgID}/overview?include_demo=${isDemo || "false"}`, addAuth(token)),
    {
      onError: error => handleError(error),
      enabled: true,
    },
  )

  const clusters = data?.data.clusters.map(cluster => cluster.clusterId)

  const certInventoryQueries = useQueries({
    queries:
      clusters?.map(cluster => {
        return {
          queryKey: QUERYKEYS.certInventory.detail(orgID, cluster),
          queryFn: () => axios.get(`/api/v1/org/${orgID}/cert-inventory/${cluster}?include_demo=true`, addAuth(token)),
        }
      }) ?? [],
  })

  interface Map {
    [key: string]: { isLoading: boolean; loadingError: string | undefined; certInventory: ClusterData }
  }

  const allClustersData: Map = certInventoryQueries.reduce((acc, cur) => {
    if (!cur.isLoading) {
      return {
        ...acc,
        [cur.data?.data.cluster_id]: {
          isLoading: cur.isLoading,
          loadingError: cur.error,
          certInventory: cur.data?.data,
        },
      }
    }
    return acc
  }, {})

  const value = useMemo(
    () => ({
      overviewStats: data?.data,
      allClustersData,
      isLoading,
      isSuccess,
      error,
      clusterError: certInventoryQueries.some(cluster => cluster.isError),
    }),
    [data?.data, allClustersData, isLoading, isSuccess, error],
  )

  return value
}
