import { useEffect } from "react"

const useDocumentTitle = (title?: string) => {
  useEffect(() => {
    const documentTitle = title ? `${process.env.REACT_APP_TITLE} | ${title}` : process.env.REACT_APP_TITLE

    // Set the browser window title
    window.document.title = documentTitle || "Jetstack Secure"
  }, [title])
}

export { useDocumentTitle }
