import React, { useCallback } from "react"
import { useSelector } from "react-redux"
import { Navigate, useParams } from "react-router"
import { Grid } from "@material-ui/core"
import { getOrgID, pathToCertInventory, pathToNewCluster } from "@routes"
import { useSupportsMultiClusterSelector } from "@hooks/useSupportsMultiCluster"
import { clustersSelector } from "@selectors/clusters"

import { Page } from "@components/Page"
import { NoClusters } from "@components/NoClusters"
import { LoadingMessage } from "@components/LoadingMessage"
import { ErrorMessage } from "@components/ErrorMessage"
import { Breadcrumbs } from "@components/Navigation/Breadcrumbs"
import { AddClusterOption } from "@components/CertInventoryMultiCluster/AddClusterOption"

import { OverviewSection } from "./OverviewSection"
import { useGetOverviewData } from "./hooks"

function OrganizationOverview() {
  const orgID = getOrgID(useParams())
  const clusters = useSelector(clustersSelector)
  const { clusterData, certificateData, ingressData, isLoading, error, clusterError } = useGetOverviewData(orgID)
  const { supportsMultiCluster, clusterLimitReached } = useSupportsMultiClusterSelector()

  if (!supportsMultiCluster) {
    return <Navigate to={pathToCertInventory(orgID)} />
  }

  const renderContent = useCallback(() => {
    return clusterData.value > 0 && !clusterError ? (
      <>
        <Grid container spacing={2}>
          {clusterData && (
            <Grid item xs={12} md={6}>
              <OverviewSection data={clusterData} orgId={orgID} hasButton />
            </Grid>
          )}
          {certificateData && (
            <Grid item xs={12} md={6}>
              <OverviewSection data={certificateData} />
            </Grid>
          )}
          {ingressData && (
            <Grid item xs={12} md={6}>
              <OverviewSection data={ingressData} />
            </Grid>
          )}
        </Grid>
      </>
    ) : (
      <NoClusters pathToNewCluster={pathToNewCluster(orgID)} />
    )
  }, [clusterData, certificateData, ingressData, clusters, orgID])

  return (
    <Page
      titleVariant="small-uppercase"
      title="Organization overview"
      reqAuth
      fixedWidth
      breadcrumbs={<Breadcrumbs current="Overview" parents={["Organization"]} />}
      customSideComponent={
        <AddClusterOption limitReached={clusterLimitReached} redirectPath={pathToNewCluster(orgID)} text="Extend cluster limit" />
      }
    >
      {isLoading || clusterData.resourcesAreLoading ? (
        <LoadingMessage label="Loading organization statistics..." />
      ) : error ? (
        // @ts-expect-error: Error.unknown
        <ErrorMessage message={error.message as string} />
      ) : (
        renderContent()
      )}
    </Page>
  )
}

export { OrganizationOverview }
