import { K8SGenericResource } from "../types"
import { IssuerStatus, SecretKeySelector } from "../cert-manager"

export enum Issuers {
  GoogleCASIssuer = "GoogleCASIssuer",
  GoogleCASClusterIssuer = "GoogleCASClusterIssuer",
  AWSPCAIssuer = "AWSPCAIssuer",
  AWSPCAClusterIssuer = "AWSPCAClusterIssuer",
}

export enum IssuerTypes {
  GoogleCas = 1,
  AwsPca = 2,
}

// Google Certificate Authority Service (CAS) Issuer & ClusterIssuer
export type GoogleCasSpec = {
  // caPoolId is The ID of the Google Private certificate authority that will sign certificates
  caPoolId?: string
  // Location is the Google Cloud Project Location
  location?: string
  // Project is the Google Cloud Project ID
  project?: string
  // Credentials is a reference to a Kubernetes Secret Key that contains Google Service Account Credentials
  credentials?: SecretKeySelector
}

export type GoogleCasIssuer = K8SGenericResource & {
  spec?: GoogleCasSpec
  status: IssuerStatus
}

export type GoogleCasClusterIssuer = K8SGenericResource & {
  spec: GoogleCasSpec
  status: IssuerStatus
}

// AWS Private Certificate Authority Issuer & ClusterIssuer
export type AwsPcaSpec = {
  // Specifies the ARN of the PCA resource
  arn: string
  // Should contain the AWS region if it cannot be inferred
  region?: string
  // Needs to be specified if you want to authorize with AWS using an access and secret key
  secretRef?: SecretKeySelector
}

export type AwsPcaIssuer = K8SGenericResource & {
  spec?: AwsPcaSpec
  status: IssuerStatus
}

export type AwsPcaClusterIssuer = K8SGenericResource & {
  spec?: AwsPcaSpec
  status: IssuerStatus
}
