import React from "react"
import { useSelector } from "react-redux"

import { isAllQuestionnairesCompleted } from "@selectors/questionnaires"
import { Question, Questionnaire } from "@components/Questionnaire"
import { IntroView } from "./IntroView"

const IntroQuestionnaire = () => {
  const questionnaireQuestionsIds = QUESTIONNAIRE_QUESTIONS.map(({ id }) => id)
  const isCompleted = useSelector(isAllQuestionnairesCompleted(questionnaireQuestionsIds))

  if (isCompleted) return null

  return <Questionnaire questions={QUESTIONNAIRE_QUESTIONS} introPage={<IntroView />} />
}

const QUESTIONNAIRE_QUESTIONS: Question[] = [
  {
    id: "use-cases",
    title: "What use cases are you looking to solve using Jetstack Secure?",
    type: "checkboxes",
    options: [
      {
        value: "gather-insights",
        label: "Gather insights about all the TLS certificates across all my clusters and get notified on non-compliance ",
      },
      {
        value: "secure-workloads",
        label: "Secure workloads in my service mesh (for e.g Istio) with certificates that can be chained back to my enterprise root trust",
      },
      {
        value: "ensure-private-keys",
        label:
          "As a security admin, ensure private keys never leave the enterprise vault while still providing seamless developer experience",
      },
      {
        value: "gain-visibility",
        label: "As a platform lead, gain visibility to the status of certificates across all clusters, remediate on policy check failures.",
      },
      {
        value: "prod-to-prod-communication",
        label: "I am looking to secure pod-to-pod communication and we are not using a service mesh",
      },
      {
        value: "other",
        label: "Other",
        type: "input",
      },
    ],
  },
  {
    id: "cert-manager-environment",
    title: "Do you have cert-manager deployed in your Kubernetes or OpenShift environment?",
    type: "radio",
    options: [
      { value: "cm-in-production", label: "Yes, in a production environment" },
      { value: "cm-not-in-production", label: "Yes, but not in production" },
      { value: "not-using-cm", label: "I am not using cert-manager" },
      { value: "na", label: "I'd rather not say" },
    ],
  },
]

export { IntroQuestionnaire }
