import React from "react"
import ReactGA from "react-ga"

import { redirectLogin } from "@actions/auth"
import { useDispatch } from "react-redux"

import { createStyles, makeStyles, Theme, Button, Typography, Link } from "@material-ui/core"
import { WarningIcon, CertManagerIconSimple, EyeCheckIcon, ActivityIcon } from "@components/Icons"
import { BlurbFeature } from "../BlurbFeature"

const noMargin = {
  marginRight: 0,
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    blurbPanelRoot: {
      padding: theme.spacing(6),
      background: "white",
      maxWidth: "81rem",
      margin: "0 auto",
      width: "100%",
      borderRadius: 5,
    },
    main: {
      display: "grid",
      overflow: "hidden",
      gridTemplateColumns: "4fr 6fr",
      gridAutoRows: "1fr",
      gridColumnGap: "3rem",
      textAlign: "center",
      [theme.breakpoints.down(1150)]: {
        display: "block",
      },
      [theme.breakpoints.down(768)]: {
        paddingBottom: theme.spacing(4),
      },
    },
    logo: {
      maxWidth: "19rem",
      padding: theme.spacing(2, 0, 6, 0),
      display: "inline-block",
      "& img": {
        width: "100%",
      },
    },
    content: {
      fontSize: "1.125rem",
      marginBottom: theme.spacing(2),
    },
    rightSide: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundSize: "contain",
      "&:svg ": {
        width: "100%",
      },
      "& img": {
        margin: "11% 3% 0 0",
        width: "89%",
        borderRadius: 5,
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
        [theme.breakpoints.down(768)]: {
          display: "none",
        },
      },
    },
    container: {
      margin: theme.spacing(2, 0),
    },
    item: {
      display: "inline-block",
      width: "calc(50% - 1rem)",
      margin: theme.spacing(2, 4, 2, 0),
      textAlign: "center",
      "&:nth-child(2n)": noMargin,
      [theme.breakpoints.down(1150)]: {
        maxWidth: "22rem",
      },
      [theme.breakpoints.down(768)]: {
        width: "100%",
        marginRight: 0,
        maxWidth: "22rem",
        "&:nth-child(2)": noMargin,
      },
    },
    button: {
      fontSize: "1.125rem",
      fontWeight: 500,
      padding: theme.spacing(2, 5),
      lineHeight: 1.4,
      marginRight: theme.spacing(6),

      [theme.breakpoints.down(610)]: {
        width: "100%",
        maxWidth: "20rem",
        margin: theme.spacing(0, 0, 4, 0),
      },
    },
    link: {
      textTransform: "uppercase",
      textDecoration: "underline",
      fontSize: "1rem",
      "&:hover": {
        textDecoration: "none",
      },
    },
    buttons: {
      marginTop: theme.spacing(4),
      textAlign: "center",
      [theme.breakpoints.down(768)]: {
        marginTop: 0,
      },
    },
  }),
)
export const BlurbPanel = () => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const handleClick = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Get Started",
    })
    dispatch(redirectLogin())
  }

  return (
    <div className={classes.blurbPanelRoot}>
      <div className={classes.main}>
        <div>
          <div className={classes.logo}>
            <img src="/static/venafi-brand.png" />
          </div>
          <Typography gutterBottom className={classes.content}>
            Jetstack Secure is the Kubernetes machine identity management solution keeping modern enterprise cloud native environments
            secured and protected.
          </Typography>
          <div className={classes.container}>
            <div className={classes.item}>
              <BlurbFeature icon={<CertManagerIconSimple />} text="cert-manager health status and alerts" noIconBackground />
            </div>
            <div className={classes.item}>
              <BlurbFeature icon={<WarningIcon />} text="Configuration status across multiple clusters" />
            </div>
            <div className={classes.item}>
              <BlurbFeature icon={<EyeCheckIcon />} text="Full visibility of all machine identities" />
            </div>
            <div className={classes.item}>
              <BlurbFeature icon={<ActivityIcon />} text="Prevent outages and maintain high availability" />
            </div>
          </div>
        </div>
        <div className={classes.rightSide}>
          <img src="/static/screenshot.jpg" alt="" />
        </div>
      </div>
      <div className={classes.buttons}>
        <Button variant="contained" color="primary" onClick={handleClick} className={classes.button}>
          Get Started
        </Button>

        <Link onClick={handleClick} className={classes.link} component="button" variant="body2" data-testid="login-button">
          Access your account
        </Link>
      </div>
    </div>
  )
}
