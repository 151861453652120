import { useEffect, useState, SetStateAction, Dispatch } from "react"
import { useSelector } from "react-redux"
import { currentOrgSelector } from "@selectors/orgs"
import { organizationsSelector } from "@selectors/auth"

type IUseCreateInstallationCommand = {
  installationCommand: string[]
  setInstallationCommand: Dispatch<SetStateAction<string[]>>
  redactedInstallationCommand: string[]
  url: string
  setUrl: Dispatch<SetStateAction<string>>
  zone: string
  setZone: Dispatch<SetStateAction<string>>
  userName?: string
  setUserName: Dispatch<SetStateAction<string>>
  password?: string
  setPassword: Dispatch<SetStateAction<string>>
  accessToken?: string
  setAccessToken: Dispatch<SetStateAction<string>>
  installVenafiOAuthHelper: boolean
  setInstallVenafiOAuthHelper: Dispatch<SetStateAction<boolean>>
  installCertDiscoveryVenafi: boolean
  setInstallCertDiscoveryVenafi: Dispatch<SetStateAction<boolean>>
  selectedProfile: string
  setSelectedProfile: Dispatch<SetStateAction<string>>
  selectedVenafiTPPAuthMethod: string
  setSelectedVenafiTPPAuthMethod: Dispatch<SetStateAction<string>>
}

type connection = {
  name: string
  url: string
  zone: string
  username?: string
  password?: string
  accessToken?: string
}

function connectionYAML(connection: connection) {
  if (connection.username && connection.password) {
    return `${connection.name}:
  url: ${connection.url}
  zone: ${connection.zone}
  username: ${connection.username}
  password: ${connection.password}
`
  }

  return `${connection.name}:
  url: ${connection.url}
  zone: ${connection.zone}
  access-token: ${connection.accessToken}
`
}

// hook to create the installation command
export function useCreateInstallationCommand(): IUseCreateInstallationCommand {
  const currentOrgID = useSelector(currentOrgSelector)
  const organizations = useSelector(organizationsSelector)
  const currentOrg = organizations?.find(o => o.id === currentOrgID)
  const tier = currentOrg?.tier

  const defaultInstallationCommand = "jsctl operator installations apply --auto-registry-credentials"

  const [url, setUrl] = useState<string>("")
  const [zone, setZone] = useState<string>("")
  const [userName, setUserName] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [accessToken, setAccessToken] = useState<string>("")
  const [installVenafiOAuthHelper, setInstallVenafiOAuthHelper] = useState<boolean>(true)
  const [installCertDiscoveryVenafi, setInstallCertDiscoveryVenafi] = useState<boolean>(false)
  const [selectedProfile, setSelectedProfile] = useState<string>("cert-manager")

  const [installationCommand, setInstallationCommand] = useState<string[]>([])
  const [redactedInstallationCommand, setRedactedInstallationCommand] = useState<string[]>([])
  const [selectedVenafiTPPAuthMethod, setSelectedVenafiTPPAuthMethod] = useState<string>("userpass")

  useEffect(() => {
    setInstallationCommand([] as string[])
    if (selectedProfile === "cert-manager") {
      setInstallationCommand([defaultInstallationCommand])
    } else {
      const flags: string[] = []
      const connections: connection[] = []
      const redactedConnections: connection[] = []

      if (url === "" || zone === "" || userName === "" || password === "") {
        return
      }

      const venafiConnectionName = "venafi"
      const c: connection = {
        name: "venafi",
        url: url,
        zone: zone,
        username: userName,
        password: password,
      }
      connections.push(c)
      const rc: connection = {
        name: "venafi",
        url: "**********",
        zone: "**********",
        username: "**********",
        password: "**********",
      }
      redactedConnections.push(rc)

      if (tier?.startsWith("custom")) {
        flags.push("--tier=enterprise-plus")
      } else if (tier?.startsWith("enterprise_plus")) {
        flags.push("--tier=enterprise-plus")
      } else if (tier?.startsWith("enterprise")) {
        flags.push("--tier=enterprise")
      }

      if (installVenafiOAuthHelper) {
        flags.push("--venafi-oauth-helper")
      }
      if (installCertDiscoveryVenafi) {
        flags.push("--cert-discovery-venafi")
        const cdvConnectionName = "cert-discovery-venafi"
        flags.push(`--experimental-cert-discovery-venafi-connection=${cdvConnectionName}`)
        if (accessToken === "") {
          return
        }
        const c: connection = {
          name: cdvConnectionName,
          url: url,
          zone: zone,
          accessToken: accessToken,
        }

        const rc: connection = {
          name: "**********",
          url: "**********",
          zone: "**********",
          accessToken: "**********",
        }
        connections.push(c)
        redactedConnections.push(rc)
      }

      const connectionFileName = "connections.yaml"

      let connectionsFileString = ""
      connections.forEach(c => {
        connectionsFileString += connectionYAML(c)
      })
      let redactedConnectionsFileString = ""
      redactedConnections.forEach(c => {
        redactedConnectionsFileString += connectionYAML(c)
      })
      const createConnectionFile = `cat << EOF > ${connectionFileName}
${connectionsFileString}EOF`
      const createRedactedConnectionFile = `cat << EOF > ${connectionFileName}
${redactedConnectionsFileString}EOF`

      flags.push(`--experimental-venafi-connections-config=${connectionFileName}`)
      flags.push(`--experimental-venafi-issuers=tpp:${venafiConnectionName}:venafi`)

      setInstallationCommand([createConnectionFile, defaultInstallationCommand + " \\\n    " + flags.join(" \\\n    ")])
      setRedactedInstallationCommand([createRedactedConnectionFile, defaultInstallationCommand + " \\\n    " + flags.join(" \\\n    ")])
    }
  }, [
    selectedProfile,
    url,
    zone,
    selectedVenafiTPPAuthMethod,
    userName,
    password,
    accessToken,
    installVenafiOAuthHelper,
    installCertDiscoveryVenafi,
  ])

  useEffect(() => {
    if (installCertDiscoveryVenafi) {
      setSelectedVenafiTPPAuthMethod("token")
    }
  }, [installCertDiscoveryVenafi])

  return {
    installationCommand,
    setInstallationCommand,
    redactedInstallationCommand,
    url,
    setUrl,
    zone,
    setZone,
    userName,
    setUserName,
    password,
    setPassword,
    accessToken,
    setAccessToken,
    installVenafiOAuthHelper,
    setInstallVenafiOAuthHelper,
    installCertDiscoveryVenafi,
    setInstallCertDiscoveryVenafi,
    selectedVenafiTPPAuthMethod,
    setSelectedVenafiTPPAuthMethod,
    selectedProfile,
    setSelectedProfile,
  }
}
