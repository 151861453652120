import React from "react"
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core"

import { CertManagerStatusCompact } from "../AddonInstallationStatusCompact/CertManagerStatusCompact"
import { AgentStatusCompact } from "../AddonInstallationStatusCompact/AgentStatusCompact"
import { ClusterData } from "@lib/certInventory"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clusterStatusRoot: {
      display: "flex",
      flexWrap: "wrap",
      gridGap: theme.spacing(2),
    },
    certManagerContainer: {
      textAlign: "left",
    },
    certManagerPanel: {
      display: "inline-block",
      padding: theme.spacing(0.6, 1.5, 0.5, 1.5),
      marginTop: theme.spacing(0.5),
      borderRadius: "5px",
      border: `1px solid ${theme.palette.grey[300]}`,
      background: "#fff",
      "& svg": {
        transform: "scale(0.85)",
      },
      "& p": {
        fontSize: "0.75rem",
      },
    },
  }),
)
type Props = {
  cluster: {
    isLoading: boolean
    loadingError: string
    certInventory?: ClusterData | undefined
  }
}

export const ClusterStatus = ({ cluster }: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.clusterStatusRoot}>
      <div className={classes.certManagerContainer} data-tut="cm-status">
        <Typography variant="caption" display="block" color="textSecondary">
          cert-manager status
        </Typography>
        <div className={classes.certManagerPanel}>
          <CertManagerStatusCompact
            data={cluster?.certInventory?.cert_manager_status}
            error={cluster?.loadingError}
            isLoading={cluster?.isLoading}
          />
        </div>
      </div>
      <div className={classes.certManagerContainer} data-tut="agent-status">
        <Typography variant="caption" display="block" color="textSecondary">
          jetstack secure agent status
        </Typography>
        <div className={classes.certManagerPanel}>
          <AgentStatusCompact
            data={cluster?.certInventory?.js_agent_status}
            error={cluster?.loadingError}
            isLoading={cluster?.isLoading}
            compact
          />
        </div>
      </div>
    </div>
  )
}
