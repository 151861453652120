export const FETCH_SETTINGS = "FETCH_SETTINGS"
export const FETCH_SETTINGS_SUCCEEDED = "FETCH_SETTINGS_SUCCEEDED"
export const FETCH_SETTINGS_FAILED = "FETCH_SETTINGS_FAILED"
export const UPDATE_SETTINGS = "UPDATE_SETTINGS"
export const UPDATE_SETTINGS_FAILED = "UPDATE_SETTINGS_FAILED"
export const UPDATE_SETTINGS_SUCCEEDED = "UPDATE_SETTINGS_SUCCEEDED"
export const CONFIG_DEMO_CLUSTER = "CONFIG_DEMO_CLUSTER"

export type SettingsActions =
  | FetchSettingsAction
  | FetchSettingsFailedAction
  | FetchSettingsSucceededAction
  | UpdateSettingsAction
  | UpdateSettingsFailedAction
  | UpdateSettingsSucceededAction
  | ConfigDemoClusterAction

// fetch actions
export interface FetchSettingsAction {
  type: typeof FETCH_SETTINGS
  orgID: string
}
export const fetchSettings = (orgID: string): FetchSettingsAction => ({
  type: FETCH_SETTINGS,
  orgID,
})
export interface FetchSettingsFailedAction {
  type: typeof FETCH_SETTINGS_FAILED
  orgID: string
  errorMessage: string
}
export const fetchSettingsFailed = (orgID: string, errorMessage: string): FetchSettingsFailedAction => ({
  type: FETCH_SETTINGS_FAILED,
  orgID,
  errorMessage,
})
export interface FetchSettingsSucceededAction {
  type: typeof FETCH_SETTINGS_SUCCEEDED
  orgID: string
  settings: Settings
}
export const fetchSettingsSucceeded = (orgID: string, settings: Settings): FetchSettingsSucceededAction => ({
  type: FETCH_SETTINGS_SUCCEEDED,
  orgID,
  settings,
})

// update actions
export interface UpdateSettingsAction {
  type: typeof UPDATE_SETTINGS
  orgID: string
  settings: Settings
}
export const updateSettings = (orgID: string, settings: Settings): UpdateSettingsAction => ({
  type: UPDATE_SETTINGS,
  orgID,
  settings,
})
export interface UpdateSettingsFailedAction {
  type: typeof UPDATE_SETTINGS_FAILED
  orgID: string
  errorMessage: string
}
export const updateSettingsFailed = (orgID: string, errorMessage: string): UpdateSettingsFailedAction => ({
  type: UPDATE_SETTINGS_FAILED,
  orgID,
  errorMessage,
})
export interface UpdateSettingsSucceededAction {
  type: typeof UPDATE_SETTINGS_SUCCEEDED
  orgID: string
  settings: Settings
}
export const updateSettingsSucceeded = (orgID: string, settings: Settings): UpdateSettingsSucceededAction => ({
  type: UPDATE_SETTINGS_SUCCEEDED,
  orgID,
  settings,
})

// specific settings
export interface ConfigDemoClusterAction {
  type: typeof CONFIG_DEMO_CLUSTER
  orgID: string
  showDemoCluster: boolean
}
export const configDemoCluster = (orgID: string, showDemoCluster: boolean): ConfigDemoClusterAction => ({
  type: CONFIG_DEMO_CLUSTER,
  orgID,
  showDemoCluster,
})
