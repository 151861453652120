import { takeEvery, put, call, select, take } from "redux-saga/effects"
import axios from "axios"
import {
  calculateCertificateRequests,
  calculateCMCertificates,
  calculateNonCMCertificates,
  calculateOwnerlessCertificateRequests,
  calculateIssuers,
  calculateIngresses,
  calculateRoutes,
} from "@lib/certInventory/resources"
import {
  FETCH_CERT_INVENTORY,
  FetchCertInventoryAction,
  fetchCertInventoryFailed,
  fetchCertInventorySuccess,
} from "../actions/certInventory"

import { accessTokenSelector } from "@selectors/auth"
import { generateErrorMessage, handleError } from "@utils/errorHandling"
import { genDemoParamsFromOptions, selectDemoParams, DemoOptions } from "@utils/demo"
import { addAuth } from "@lib/auth"

import { settingsSelector } from "@selectors/settings"
import { fetchSettings, FETCH_SETTINGS_SUCCEEDED } from "@actions/settings"
import { filterOutByIdentity } from "./helpers/filter-out-by-identity"

function getCertInventory(accessToken: string, orgID: string, clusterID: string, demoOpts: DemoOptions) {
  return axios.get(
    `/api/v1/org/${orgID}/cert-inventory/${clusterID}`,
    addAuth(accessToken, {
      params: selectDemoParams(new URLSearchParams(window.location.search), genDemoParamsFromOptions(demoOpts)),
    }),
  )
}

function* fetchCertInventorySaga(action: FetchCertInventoryAction) {
  const { orgID, clusterID, isDemo } = action
  try {
    let orgSettings: { isLoading: boolean; settings: Settings } = yield select(settingsSelector())

    if (orgSettings?.settings === undefined) {
      if (!orgSettings?.isLoading) {
        yield put(fetchSettings(action.orgID))
      }
      yield take(FETCH_SETTINGS_SUCCEEDED)
      orgSettings = yield select(settingsSelector())
    }

    const token: string = yield select(accessTokenSelector)
    const { data } = yield call(getCertInventory, token, orgID, clusterID, { includeDemo: isDemo })

    const calculated = {
      certManagerCertificates: calculateCMCertificates(data),
      nonCertManagerCertificates: calculateNonCMCertificates(data),
      ephemeralCertificates: calculateOwnerlessCertificateRequests(data),
      certificateRequests: calculateCertificateRequests(data),
      issuers: calculateIssuers(data),
      ingresses: calculateIngresses(data),
      routes: calculateRoutes(data),
    }

    const allCertsGroupedByIdentity = filterOutByIdentity([
      ...calculated.certManagerCertificates,
      ...calculated.nonCertManagerCertificates,
      ...calculated.ephemeralCertificates,
    ])

    yield put(fetchCertInventorySuccess(orgID, clusterID, data, calculated, allCertsGroupedByIdentity))
  } catch (error) {
    handleError(error, `Unable to load cert inventory`)
    yield put(fetchCertInventoryFailed(orgID, clusterID, `Unable to load cert inventory - ${generateErrorMessage(error)}`))
  }
}

export function* certInventorySaga() {
  yield takeEvery(FETCH_CERT_INVENTORY, fetchCertInventorySaga)
}
