export type SvcAccountCreds = {
  user_id: string
  user_secret: string
  client_id?: string
  client_secret?: string
  domain?: string
}

export type APIToken = {
  token: string
  name: string
  id: number
}

const isAPIToken = (obj: SvcAccountCreds | APIToken): obj is APIToken => {
  return (<APIToken>obj).token !== undefined
}

const isSvcAccountCreds = (obj: SvcAccountCreds | APIToken): obj is SvcAccountCreds => {
  return (<SvcAccountCreds>obj).user_id !== undefined
}

function addSecretObject(creds: SvcAccountCreds | APIToken): string {
  if (isAPIToken(creds)) {
    return `---
apiVersion: v1
kind: Secret
metadata:
  name: agent-credentials
  namespace: jetstack-secure
data:
  apitoken: ${btoa(creds.token)}
EOF`
  }

  if (isSvcAccountCreds(creds)) {
    return `---
apiVersion: v1
kind: Secret
metadata:
  name: agent-credentials
  namespace: jetstack-secure
data:
  credentials.json: ${btoa(JSON.stringify(creds))}
EOF`
  }

  return `EOF`
}

export function genAgentCommand(
  orgID: string,
  clusterName: string,
  creds: SvcAccountCreds | APIToken | null,
  useNoAuth: boolean,
  includeAgentInstallation: boolean,
) {
  const server = `${window.location.protocol}//${window.location.host}`
  let configCommand = `kubectl apply -f - <<EOF
apiVersion: v1
kind: Namespace
metadata:
  name: jetstack-secure
---
apiVersion: v1
kind: ConfigMap
metadata:
  name: agent-config
  namespace: jetstack-secure
data:
  config.yaml: |-
    server: "${server}"
    organization_id: "${orgID}"
    cluster_id: "${clusterName}"
    data-gatherers:
    # gather k8s apiserver version information
    - kind: "k8s-discovery"
      name: "k8s-discovery"
    # pods data is used in the pods and application_versions packages
    - kind: "k8s-dynamic"
      name: "k8s/pods"
      config:
        resource-type:
          resource: pods
          version: v1
    # gather services for pod readiness probe rules
    - kind: "k8s-dynamic"
      name: "k8s/services"
      config:
        resource-type:
          resource: services
          version: v1
    # gather higher level resources to ensure data to determine ownership is present
    - kind: "k8s-dynamic"
      name: "k8s/deployments"
      config:
        resource-type:
          version: v1
          resource: deployments
          group: apps
    - kind: "k8s-dynamic"
      name: "k8s/replicasets"
      config:
        resource-type:
          version: v1
          resource: replicasets
          group: apps
    - kind: "k8s-dynamic"
      name: "k8s/statefulsets"
      config:
        resource-type:
          version: v1
          resource: statefulsets
          group: apps
    - kind: "k8s-dynamic"
      name: "k8s/daemonsets"
      config:
        resource-type:
          version: v1
          resource: daemonsets
          group: apps
    - kind: "k8s-dynamic"
      name: "k8s/jobs"
      config:
        resource-type:
          version: v1
          resource: jobs
          group: batch
    - kind: "k8s-dynamic"
      name: "k8s/cronjobs"
      config:
        resource-type:
          version: v1beta1
          resource: cronjobs
          group: batch
    # gather resources for cert-manager package
    - kind: "k8s-dynamic"
      name: "k8s/secrets"
      config:
        resource-type:
          version: v1
          resource: secrets
    - kind: "k8s-dynamic"
      name: "k8s/certificates"
      config:
        resource-type:
          group: cert-manager.io
          version: v1
          resource: certificates
    - kind: "k8s-dynamic"
      name: "k8s/ingresses"
      config:
        resource-type:
          group: networking.k8s.io
          version: v1
          resource: ingresses
    - kind: "k8s-dynamic"
      name: "k8s/certificaterequests"
      config:
        resource-type:
          group: cert-manager.io
          version: v1
          resource: certificaterequests
    - kind: "k8s-dynamic"
      name: "k8s/issuers"
      config:
        resource-type:
          group: cert-manager.io
          version: v1
          resource: issuers
    - kind: "k8s-dynamic"
      name: "k8s/clusterissuers"
      config:
        resource-type:
          group: cert-manager.io
          version: v1
          resource: clusterissuers
    - kind: "k8s-dynamic"
      name: "k8s/googlecasissuers"
      config:
        resource-type:
          group: cas-issuer.jetstack.io
          version: v1beta1
          resource: googlecasissuers
    - kind: "k8s-dynamic"
      name: "k8s/googlecasclusterissuers"
      config:
        resource-type:
          group: cas-issuer.jetstack.io
          version: v1beta1
          resource: googlecasclusterissuers
    - kind: "k8s-dynamic"
      name: "k8s/awspcaissuer"
      config:
        resource-type:
          group: awspca.cert-manager.io
          version: v1beta1
          resource: awspcaissuers
    - kind: "k8s-dynamic"
      name: "k8s/awspcaclusterissuers"
      config:
        resource-type:
          group: awspca.cert-manager.io
          version: v1beta1
          resource: awspcaclusterissuers
    - kind: "k8s-dynamic"
      name: "k8s/mutatingwebhookconfigurations"
      config:
        resource-type:
          group: admissionregistration.k8s.io
          version: v1
          resource: mutatingwebhookconfigurations
    - kind: "k8s-dynamic"
      name: "k8s/validatingwebhookconfigurations"
      config:
        resource-type:
          group: admissionregistration.k8s.io
          version: v1
          resource: validatingwebhookconfigurations
    - kind: "k8s-dynamic"
      name: "k8s/gateways"
      config:
        resource-type:
          group: networking.istio.io
          version: v1alpha3
          resource: gateways
    - kind: "k8s-dynamic"
      name: "k8s/virtualservices"
      config:
        resource-type:
          group: networking.istio.io
          version: v1alpha3
          resource: virtualservices
    - kind: "k8s-dynamic"
      name: "k8s/routes"
      config:
        resource-type:
          version: v1
          group: route.openshift.io
          resource: routes
`

  if (creds !== null && !useNoAuth) {
    configCommand = configCommand + addSecretObject(creds)
  } else {
    configCommand = configCommand + `EOF`
  }

  const installationCommand = `kubectl apply -f ${window.location.protocol}//${window.location.host}/install/agent.yaml`

  let command = configCommand
  if (includeAgentInstallation) {
    command = `{ ${configCommand}
} && ${installationCommand}`
  }

  return command
}
