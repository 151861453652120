import React from "react"
import { createStyles, makeStyles, Theme as MUITheme, Link } from "@material-ui/core"

const useStyles = makeStyles((theme: MUITheme) =>
  createStyles({
    downloadBtn: {
      marginBottom: theme.spacing(3),
    },
  }),
)

type DownloadFileProps = {
  type: string
  filename: string
  content: string
  text: string
}

export const DownloadFile = ({ type, filename, content, text }: DownloadFileProps) => {
  const classes = useStyles()
  return (
    <Link
      href={`data:${type};base64,${Buffer.from(content, "binary").toString("base64")}`}
      download={filename}
      className={classes.downloadBtn}
    >
      {text}
    </Link>
  )
}
