import { ServiceAccount } from "@lib/serviceAccounts"

enum Tier {
  Custom = "custom",
  Free = "free",
  Staff = "venafi_staff",
  Enterprise10 = "enterprise_10",
  Enterprise20 = "enterprise_20",
  Enterprise40 = "enterprise_40",
  Enterprise60 = "enterprise_60",
  Enterprise100 = "enterprise_100",
  EnterprisePlus10 = "enterprise_plus_10",
  EnterprisePlus20 = "enterprise_plus_20",
  EnterprisePlus40 = "enterprise_plus_40",
  EnterprisePlus60 = "enterprise_plus_60",
  EnterprisePlus100 = "enterprise_plus_100",
}

enum SupportLevel {
  BestEffort = "best_effort",
  BusinessHours = "business_hours",
  Support24x7 = "24x7",
  Venafi24x7 = "venafi_24x7",
}

export const tierName = (tier?: string): string | undefined => {
  switch (tier) {
    case Tier.Custom:
      return "Custom"
    case Tier.Free:
      return "Free"
    case Tier.Staff:
      return "Venafi Staff"
    case Tier.Enterprise10:
    case Tier.Enterprise20:
    case Tier.Enterprise40:
    case Tier.Enterprise60:
    case Tier.Enterprise100:
      return "Enterprise"
    case Tier.EnterprisePlus10:
    case Tier.EnterprisePlus20:
    case Tier.EnterprisePlus40:
    case Tier.EnterprisePlus60:
    case Tier.EnterprisePlus100:
      return "Enterprise+"
  }
}

const JETSTACK_SUPPORT_EMAIL = "jetstack-secure@jetstack.io"
const JETSTACK_SUPPORT_URL = "https://support.jetstack.io"
const VENAFI_SUPPORT_URL = "https://support.venafi.com"
// JETSTACK_INTERNAL_CHANNEL is a link to the #js-control-plane channel in slack
const JETSTACK_INTERNAL_CHANNEL = "https://jetstack.slack.com/archives/CL7JP609K"

const supportLinks: Record<Tier, string> = {
  [Tier.Custom]: `mailto:${JETSTACK_SUPPORT_EMAIL}`,
  [Tier.Free]: `mailto:${JETSTACK_SUPPORT_EMAIL}`,
  [Tier.Staff]: JETSTACK_INTERNAL_CHANNEL,
  [Tier.Enterprise10]: JETSTACK_SUPPORT_URL,
  [Tier.Enterprise20]: JETSTACK_SUPPORT_URL,
  [Tier.Enterprise40]: JETSTACK_SUPPORT_URL,
  [Tier.Enterprise60]: JETSTACK_SUPPORT_URL,
  [Tier.Enterprise100]: JETSTACK_SUPPORT_URL,
  [Tier.EnterprisePlus10]: JETSTACK_SUPPORT_URL,
  [Tier.EnterprisePlus20]: JETSTACK_SUPPORT_URL,
  [Tier.EnterprisePlus40]: JETSTACK_SUPPORT_URL,
  [Tier.EnterprisePlus60]: JETSTACK_SUPPORT_URL,
  [Tier.EnterprisePlus100]: JETSTACK_SUPPORT_URL,
}

const supportTexts: Record<SupportLevel, string> = {
  [SupportLevel.BestEffort]: "Best Effort",
  [SupportLevel.BusinessHours]: "Business Hours",
  [SupportLevel.Support24x7]: "24x7",
  [SupportLevel.Venafi24x7]: "24x7",
}

export const supportInfo = (supportLevel?: string, tier?: string) => {
  const supportLink =
    // in the case of venafi customer, determine support link based on support level
    supportLevel === SupportLevel.Venafi24x7
      ? VENAFI_SUPPORT_URL
      : // otherwise use the tier
        supportLinks[tier as Tier] || supportLinks[Tier.Custom]

  const supportText = supportTexts[supportLevel as SupportLevel] || supportTexts[SupportLevel.BusinessHours]
  return { supportText, supportLink }
}

type Order = "asc" | "desc"

export function sortSvcAccountsBy(array: ServiceAccount[], order: Order, orderBy: string) {
  switch (orderBy) {
    case "created_at":
    case "last_login":
      array.sort((a, b) => {
        const da = new Date(a[orderBy])
        const db = new Date(b[orderBy])
        return da.getTime() - db.getTime()
      })
      if (order === "desc") {
        return array.reverse()
      }
      return array
    case "email":
      array.sort((a, b) => a[orderBy].localeCompare(b[orderBy]))
      if (order === "desc") {
        return array.reverse()
      }
      return array
    default:
      break
  }
}
