import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { Button, createStyles, makeStyles, Theme, Typography, TextField, FormControlLabel, Switch } from "@material-ui/core"

import { getOrgID } from "@routes"
import { createReceiver, updateReceiver, deleteReceiver } from "@actions/receivers"
import { receiversSelector } from "@selectors/receivers"
import { LoadingMessage } from "@components/LoadingMessage"

import { Channel } from "../types"
import { getReceiverConfig } from "../helpers"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    input: {
      width: "100%",
      margin: theme.spacing(2, 0),
    },
    buttons: {
      display: "flex",
      gap: theme.spacing(1.5),
      marginTop: theme.spacing(1),
    },
    disableButton: {
      background: theme.palette.error.main,
    },
    content: {
      marginTop: theme.spacing(2),
      paddingRight: theme.spacing(0.5),
    },

    deleteButton: {
      color: "#fff",
      background: theme.palette.error.main,
    },
  }),
)

type Props = {
  onCancel: () => void
  cluster: string
  type?: Channel
  receiver?: Receiver
}

export function ConfigForm({ type, onCancel, receiver, cluster }: Props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const params = useParams<{ orgId: string; clusterID: string; clusterView: string }>()
  const organization = getOrgID(params)

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    hook: "",
  })
  const [receiverName, setReceiverName] = useState("")
  const [webhook, setWebhook] = useState("")
  const [enableWarnings, setEnableWarnings] = useState(false)
  const [enableSystemCerts, setEnableSystemCerts] = useState(false)
  const receiversData = useSelector(receiversSelector(cluster))

  useEffect(() => {
    if (receiver) {
      setReceiverName(receiver.display_name)
      if (type === "slack") {
        setWebhook(receiver.slack_webhook_url)
      } else if (type === "microsoft-teams") {
        setWebhook(receiver.microsoft_teams_webhook_url)
      }
      setEnableWarnings(receiver.send_warnings)
      setEnableSystemCerts(receiver.alert_on_system_certificates)
    }
  }, [receiver])

  const handleSave = () => {
    if (!isValidWebhook(webhook, type)) {
      setErrorMessages(state => ({ ...state, hook: "Invalid webhook" }))
      return
    }

    const partialReceiverData = {
      display_name: receiverName,
      organization: organization,
      cluster: cluster,
      slack_webhook_enabled: type === "slack",
      slack_webhook_url: type === "slack" ? webhook : "",
      microsoft_teams_webhook_enabled: type === "microsoft-teams",
      microsoft_teams_webhook_url: type === "microsoft-teams" ? webhook : "",
      send_errors: true, // not editable by form
      send_warnings: enableWarnings,
      alert_on_system_certificates: enableSystemCerts,
    }
    if (receiver) {
      dispatch(
        updateReceiver(organization, cluster, {
          id: receiver.id,
          ...partialReceiverData,
        }),
      )
      handleCancel()
    } else {
      if (receiverName && webhook) {
        dispatch(createReceiver(organization, cluster, { id: undefined, ...partialReceiverData }))
        handleCancel()
      } else {
        const errorMessages = {
          hook: !webhook ? "Webhook is required" : "",
          name: !receiverName ? "Name is required" : "",
        }
        setErrorMessages(errorMessages)
      }
    }
  }

  const handleDelete = () => {
    if (receiver && receiver.id) {
      dispatch(deleteReceiver(organization, cluster, receiver.id))
      handleCancel()
    }
  }

  const handleCancel = () => {
    onCancel()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    if (name === "display-name") {
      setReceiverName(value)
    } else if (name === "hook-url") {
      setWebhook(value)
    }
    setErrorMessages({
      name: "",
      hook: "",
    })
  }

  const isValidWebhook = (url: string, type: Channel | undefined): boolean => {
    if (type === "slack") {
      return /^https:\/\/hooks.slack.com/.test(url) && isUrl(url)
    }
    if (type === "microsoft-teams") {
      return !/^https:\/\/hooks.slack.com/.test(url) && isUrl(url)
    }
    return false
  }

  function isUrl(url: string) {
    const regexp =
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/
    return regexp.test(url)
  }

  const config = getReceiverConfig(type)
  const isCreateMode = !receiver

  if (receiversData?.isLoading) {
    return <LoadingMessage label={isCreateMode ? "Creating your webhook..." : "Updating your webhook..."} />
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="body1" align="left">
          You can {isCreateMode ? "create a new " : " learn how to change a"} webhook in {config?.name}{" "}
          <a target="_blank" rel="noreferrer" href={config?.docLink}>
            here
          </a>
        </Typography>
        <div className={classes.input}>
          <TextField
            inputProps={{
              "data-testid": "alerts-name-input",
            }}
            name="display-name"
            error={Boolean(errorMessages.name)}
            label="Name your receiver"
            helperText={errorMessages.name}
            variant="outlined"
            onChange={handleChange}
            value={receiverName}
            size="small"
            fullWidth
          />
        </div>
        <div className={classes.input}>
          <TextField
            inputProps={{
              "data-testid": "alerts-hook-input",
            }}
            name="hook-url"
            error={Boolean(errorMessages.hook)}
            label={config?.label}
            helperText={errorMessages.hook !== "" ? errorMessages.hook : config?.urlExample}
            variant="outlined"
            onChange={handleChange}
            value={webhook}
            size="small"
            fullWidth
          />
        </div>
        <div>
          <FormControlLabel
            data-testid={"alerts-warnings-checkbox"}
            control={<Switch checked={enableWarnings} onChange={event => setEnableWarnings(event.target.checked)} color="primary" />}
            label="Include warnings"
          />
          <FormControlLabel
            data-testid={"alerts-system-certificates-checkbox"}
            control={<Switch checked={enableSystemCerts} onChange={event => setEnableSystemCerts(event.target.checked)} color="primary" />}
            label="Include system certificates"
          />
        </div>
        <div className={classes.buttons}>
          <Button variant="contained" size="small" color="primary" onClick={handleSave}>
            {isCreateMode ? "Create" : "Save Changes"}
          </Button>
          {!isCreateMode && (
            <Button variant="contained" size="small" color="primary" className={classes.deleteButton} onClick={handleDelete}>
              Delete
            </Button>
          )}
          <Button variant="contained" size="small" color="primary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}
