import { AppState } from "../reducers"
import { currentOrgSelector } from "./orgs"

export function receiversSelector(clusterID: string) {
  return (state: AppState) => {
    if (!clusterID) {
      return
    }
    const orgID = currentOrgSelector(state)
    const key = `${orgID}/${clusterID}`
    if (key in state.receivers.receivers) {
      return state.receivers.receivers[`${orgID}/${clusterID}`]
    }
  }
}
