export type AppConfig = {
  auth: AppConfigAuth
  agent_installation: AppConfigAgentInstallation
  global_features: GlobalFeatures
  analytics: AppConfigAnalytics
}

export type AppConfigAuth = {
  kind: AppConfigAuthKind
  auth0?: AppConfigAuthAuth0
  oauth2?: AppConfigAuthOAuth2
}

export enum AppConfigAuthKind {
  auth0 = "auth0",
  disabled = "disabled",
  oauth2 = "oauth2",
}

export type AppConfigAuthAuth0 = {
  domain: string
  client_id: string
}

export type AppConfigAuthOAuth2 = {
  login_url: string
}

export enum AppConfigAuthAgentKind {
  service_account = "service_account",
  api_token = "api_token",
}

export type AppConfigAgentInstallation = {
  disable_auth: boolean
  auth_type?: AppConfigAuthAgentKind
}

export type GlobalFeatures = {
  integrations?: boolean
  academy?: boolean
  questionnaires?: boolean
  customer_portal?: boolean
  cluster_limit?: boolean
}

export type AppConfigAnalytics = {
  sentry?: AppConfigAnalyticsSentry
  ga?: AppConfigAnalyticsGA
  rudderstack?: AppConfigAnalyticsRudderstack
  hotjar?: AppConfigAnalyticsHotjar
}

export type AppConfigAnalyticsSentry = {
  dsn: string
}

export type AppConfigAnalyticsGA = {
  tracking_code: string
}

export type AppConfigAnalyticsRudderstack = {
  data_plane_url: string
  write_key: string
}

export type AppConfigAnalyticsHotjar = {
  hjid: number
  hjsv: number
}
