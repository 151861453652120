import { takeEvery, call, select, put, take } from "redux-saga/effects"
import axios from "axios"
import { AxiosError } from "axios"

import {
  FETCH_SETTINGS,
  UPDATE_SETTINGS,
  updateSettings,
  UpdateSettingsAction,
  updateSettingsSucceeded,
  updateSettingsFailed,
  FetchSettingsAction,
  fetchSettingsSucceeded,
  fetchSettingsFailed,
  CONFIG_DEMO_CLUSTER,
  ConfigDemoClusterAction,
  fetchSettings,
  FETCH_SETTINGS_SUCCEEDED,
} from "@actions/settings"

import { UpdateCurrentOrgAction } from "@actions/orgs"

import { accessTokenSelector } from "@selectors/auth"
import { generateErrorMessage, handleError } from "@utils/errorHandling"
import { FetchAuthInfoSuccessAction, FETCH_AUTH_INFO_SUCCESS } from "@actions/auth"
import { settingsSelector } from "@selectors/settings"
import { noopAlreadyHappening } from "@actions/noop"
import { addAuth } from "@lib/auth"

function reqUpdateSettings(accessToken: string, settings: Settings) {
  return axios.patch(`/api/v1/org/${settings.org_id}/settings`, settings, addAuth(accessToken)).catch((error: AxiosError) => {
    if (error.request) {
      handleError(error, "request error")
    } else {
      handleError(error, "axios error")
    }
  })
}
function* updateSettingsSaga(action: UpdateSettingsAction) {
  try {
    const token: string = yield select(accessTokenSelector)
    const { data } = yield call(reqUpdateSettings, token, action.settings)

    if (data as Settings) {
      yield put(updateSettingsSucceeded(action.orgID, data))
    } else {
      yield put(updateSettingsFailed(action.orgID, `Unable to update settings - missing data in response`))
    }
  } catch (error) {
    handleError(error, `Unable to update settings`)
    yield put(updateSettingsFailed(action.orgID, `Unable to update settings - ${generateErrorMessage(error)}`))
  }
}

function reqFetchSettings(accessToken: string, orgID: string) {
  const data = axios.get(`/api/v1/org/${orgID}/settings`, addAuth(accessToken)).catch((error: AxiosError) => {
    if (error.request) {
      handleError(error, "request error")
    } else {
      handleError(error, "axios error")
    }
  })
  return data
}
function* fetchSettingsSaga(action: FetchSettingsAction | UpdateCurrentOrgAction | FetchAuthInfoSuccessAction) {
  const orgID = action.type === FETCH_AUTH_INFO_SUCCESS ? action.currentOrg : action.orgID
  try {
    const token: string = yield select(accessTokenSelector)
    // @ts-expect-error: TODO: Fix this
    const data = yield call(reqFetchSettings, token, orgID)
    if (data?.data) {
      yield put(fetchSettingsSucceeded(orgID, data.data))
    } else {
      yield put(fetchSettingsFailed(orgID, `Unable to fetch settings - missing data in response`))
    }
  } catch (error) {
    handleError(error, `Unable to fetch settings`)
    yield put(fetchSettingsFailed(orgID, `Unable to fetch org settings - ${generateErrorMessage(error)}`))
  }
}

function* configDemoCluster(action: ConfigDemoClusterAction) {
  try {
    let orgSettings: { isLoading: boolean; settings: Settings } = yield select(settingsSelector())
    if (!orgSettings.settings) {
      if (orgSettings.isLoading) {
        yield put(noopAlreadyHappening(action))
      } else {
        yield put(fetchSettings(action.orgID))
      }
      yield take(FETCH_SETTINGS_SUCCEEDED)
      orgSettings = yield select(settingsSelector())
    }

    if (orgSettings.settings.show_demo_cluster !== action.showDemoCluster) {
      orgSettings.settings.show_demo_cluster = action.showDemoCluster
      yield put(updateSettings(action.orgID, orgSettings.settings))
    }
  } catch (error) {
    handleError(error, `Unable to config demo cluster setting`)
    yield put(fetchSettingsFailed(action.orgID, `Unable to config demo cluster setting - ${generateErrorMessage(error)}`))
  }
}

export function* settingsSaga() {
  yield takeEvery(FETCH_SETTINGS, fetchSettingsSaga)
  yield takeEvery(FETCH_AUTH_INFO_SUCCESS, fetchSettingsSaga)
  yield takeEvery(UPDATE_SETTINGS, updateSettingsSaga)
  yield takeEvery(CONFIG_DEMO_CLUSTER, configDemoCluster)
}
