import React from "react"
import ReactDOM from "react-dom"
import ReactGA from "react-ga"
import { BrowserRouter as Router } from "react-router-dom"
import { hotjar } from "react-hotjar"
import { Provider } from "react-redux"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { createStore, applyMiddleware } from "redux"
import createSagaMiddleware from "redux-saga"
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction"
import { createBrowserHistory } from "history"
import axios from "axios"
import { getCookieConsentValue } from "react-cookie-consent"

import { rudderanalytics as rudderstack } from "@utils/rudderstack"

import { reducers as createRootReducer } from "./reducers"
import { rootSaga } from "./sagas"

import { AppConfig } from "@components/AppConfig"
import { App } from "@components/App"
import { Auth } from "@components/Auth"
import "./reset.css"
import "./fonts.css"
import { AppConfigAnalytics } from "@lib/appConfig"

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const rootReducer = createRootReducer()

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)))

sagaMiddleware.run(rootSaga)
const isProduction = process.env.NODE_ENV === "production"
const optionalCookiesEnabled = getCookieConsentValue() === "true"

history.listen(location => {
  if (optionalCookiesEnabled && isProduction) {
    ReactGA.ga("set", "page", location.pathname + location.search)
    ReactGA.ga("send", "pageview")
    rudderstack.page()
  }
})

const enableAnalytics = (config?: AppConfigAnalytics) => {
  if (config && isProduction) {
    // Sentry
    if (config.sentry) {
      Sentry.init({
        dsn: config.sentry.dsn,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
      })
    }

    // Google Analytics
    if (config.ga) {
      ReactGA.initialize(config.ga.tracking_code)
      ReactGA.pageview(window.location.pathname + window.location.search)
    }

    // Rudderstack
    if (config.rudderstack) {
      rudderstack.load(config.rudderstack.write_key, config.rudderstack.data_plane_url, { logLevel: "WARN" })
    }

    // Hotjar
    if (config.hotjar) {
      hotjar.initialize(config.hotjar.hjid, config.hotjar.hjsv)
    }
  }
}

axios.interceptors.response.use(
  response => response,
  function (error) {
    // users of axios can specify config.isBackground = true to skip the redirect in case of 401
    if (!error.config.isBackground && error.response.status === 401) {
      // Whenever a 401 Unauthorized response is received, we redirect to the login page
      window.location.replace("/login")
    }
    return Promise.reject(error)
  },
)

ReactDOM.render(
  <Provider store={store}>
    <AppConfig>
      <Router>
        <Auth>
          <App enableAnalytics={enableAnalytics} />
        </Auth>
      </Router>
    </AppConfig>
  </Provider>,
  document.getElementById("root"),
)
