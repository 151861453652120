import { AppConfigAuth, AppConfigAuthOAuth2 } from "@lib/appConfig"
import axios, { AxiosRequestConfig } from "axios"
import { RedirectResult } from "./types"

type oauth2State = {
  token?: string
  isAuthenticated?: boolean
}

export class OAuth2AuthClient {
  config: AppConfigAuthOAuth2
  state: oauth2State

  constructor(cfg: AppConfigAuth) {
    if (!cfg.oauth2) {
      throw new Error("cannot create oauth2 client, missing oauth2 config")
    }
    this.config = cfg.oauth2
    this.state = {}
  }

  async initialize() {
    return this
  }

  async redirectToLogin() {
    return new Promise<void>(resolve => {
      window.location.href = this.config.login_url
      resolve()
    })
  }

  async handleRedirectCallback(): Promise<RedirectResult | undefined> {
    return new Promise<RedirectResult | undefined>(resolve => {
      resolve({
        appState: {
          targetUrl: "/",
        },
      })
    })
  }

  async getToken(): Promise<string | undefined> {
    return new Promise<string | undefined>(resolve => {
      // TODO: get token
      resolve(undefined)
    })
  }

  async isAuthenticated(): Promise<boolean | undefined> {
    return new Promise<boolean | undefined>(resolve => {
      axios.get("/oauth2/auth", { isBackground: true } as AxiosRequestConfig).then(
        () => {
          this.state.isAuthenticated = true
          resolve(!!this.state.isAuthenticated)
        },
        () => {
          this.state.isAuthenticated = false
          resolve(!!this.state.isAuthenticated)
        },
      )
    })
  }

  async logout(): Promise<void> {
    return new Promise<void>(resolve => {
      window.location.href = this.config.login_url + "/sign_out"
      resolve()
    })
  }
}
