import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles, createStyles, Theme, Grid, Typography } from "@material-ui/core"
import { uniqBy } from "lodash"

import { ClusterData } from "@lib/certInventory"
import { Resource, calculateIngresses, calculateRoutes, Route, Ingress } from "@lib/certInventory/resources"
import { useGetAllCertInventories } from "@hooks/useGetAllCertInventories"
import { sliceListByPage } from "@utils/utils"
import { CollatedResources } from "@utils/certificate"
import { updateFilters } from "@actions/filters"
import { filterOptionsSelector, filterSelector, FilterType } from "@selectors/filters"

import { ErrorMessage } from "@components/ErrorMessage"
import { StatusBar } from "@components/StatusBar"
import { FiltersBar, FilterProps } from "@components/FiltersBar"
import { Pagination } from "@components/Pagination"
import { LoadingMessage } from "@components/LoadingMessage"
import { Table, TableHeader, IngressRow } from "@components/ResourcesTable"
import { sortByStatus, getSortedFilteredIngresses } from "./helpers"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
    },
    status: {
      flex: 1,
      marginBottom: theme.spacing(2),
    },
    error: {
      marginTop: theme.spacing(4),
    },
    paginationContainer: {
      textAlign: "center",
      marginBottom: theme.spacing(2),
    },
    errorContainer: {
      background: theme.palette.grey[200],
      borderRadius: 5,
      display: "inline-block",
      padding: theme.spacing(1, 2),
      marginBottom: theme.spacing(2),
    },
  }),
)

type Props = {
  data?: ClusterData
  isLoading: boolean
  error: string
  handleSelect: (res: Resource, hostName?: string) => void
  activeResource?: Resource
  // activeResourceHash: if provided and different than activeResource.hash() it will call handleSelect.
  activeResourceHash?: string
  cluster?: string
  allResourcesView?: boolean
  allClustersResources?: CollatedResources
}

export type IngressWithHost = {
  hostName: string
  resource: Ingress | Route
}

export function Ingresses({
  data,
  handleSelect,
  activeResource,
  isLoading,
  error,
  activeResourceHash,
  cluster,
  allResourcesView,
  allClustersResources,
}: Props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [ingresses, setIngresses] = useState<IngressWithHost[]>([])
  const [statistics, setStatistics] = useState({
    high: 0,
    medium: 0,
    low: 0,
  })
  const [currentPage, setCurrentPage] = useState(1)
  const { certInventory } = useGetAllCertInventories(!allResourcesView)
  const filtersState = useSelector(filterSelector(FilterType.ingresses))
  const filterOpts = useSelector(filterOptionsSelector(FilterType.ingresses, cluster))
  const perPage = 10
  let totalPages = 1

  useEffect(() => {
    let hostListIngresses: IngressWithHost[] = []
    if (allResourcesView && allClustersResources) {
      hostListIngresses = getHostList(allClustersResources.ingresses).concat(getRouteHostList(allClustersResources.routes))
    } else if (data) {
      hostListIngresses = getHostList(calculateIngresses(data)).concat(getRouteHostList(calculateRoutes(data)))
    }
    setIngresses(hostListIngresses)
    setStatistics(extractStatistics(hostListIngresses))
  }, [data, allResourcesView, certInventory])

  useEffect(() => {
    const hashes = activeResourceHash?.split("#") || []
    if ((data || !isLoading) && ingresses.length > 0 && hashes[0] && (!activeResource || hashes[0] !== activeResource.hash())) {
      const ingress = ingresses.find(ingress => ingress.resource.hash() === hashes[0])
      if (ingress?.resource) {
        handleSelect(ingress.resource, hashes[1])
      }
    }
  }, [activeResource, activeResourceHash, data, ingresses, isLoading])

  const dispatchFilter = (filter: FilterProps) => dispatch(updateFilters({ ingresses: filter }))

  const onFilter = (filter: FilterProps) => {
    dispatchFilter(filter)
  }

  const getHostList = (ingresses: Ingress[]): IngressWithHost[] => {
    const hosts: IngressWithHost[] = []

    for (const ingress of ingresses) {
      if (ingress.resource.spec?.rules && ingress.resource.spec?.rules.length > 0) {
        for (const host of ingress.resource.spec?.rules) {
          hosts.push({
            hostName: host.host,
            resource: ingress,
          })
        }
      } else if (ingress.resource.spec?.defaultBackend && ingress.resource.spec?.defaultBackend.service) {
        // if no rules are specified then the ingress should have a '.spec.defaultBackend'
        const service = ingress.resource.spec?.defaultBackend?.service
        hosts.push({
          hostName: service.name,
          resource: ingress,
        })
      }
    }
    return uniqBy(hosts, "hostName")
  }
  const getRouteHostList = (routes: Route[]): IngressWithHost[] => {
    const hosts: IngressWithHost[] = []

    for (const route of routes) {
      if (!route.resource.spec?.host) {
        continue
      }

      hosts.push({
        hostName: route.resource.spec?.host,
        resource: route,
      })
    }
    return uniqBy(hosts, "hostName")
  }

  const getIngresses = () => {
    if (isLoading || (!allResourcesView && (!data || !data.kind_index))) {
      return <div>Loading...</div>
    }

    const generateIngressRow = (ingress: IngressWithHost) => {
      const { hostName, resource } = ingress
      const activeResourceId = activeResource ? activeResource?.id + activeResource?.clusterId : undefined
      const selected = resource.id + resource.clusterId === activeResourceId && hostName === activeResourceHash?.split("#")[1]
      return <IngressRow key={hostName + resource.id} ingress={ingress} selected={selected} handleSelect={handleSelect} />
    }
    const allSortedByStatus = sortByStatus([...ingresses])

    const filteredSortedIngresses = getSortedFilteredIngresses(filtersState, allSortedByStatus)

    const hasFilters = filteredSortedIngresses.length > 0 || Object.keys(filtersState).length > 0

    totalPages = hasFilters ? Math.ceil(filteredSortedIngresses.length / perPage) : Math.ceil(allSortedByStatus.length / perPage) || 1

    if (totalPages < currentPage) {
      setCurrentPage(totalPages)
    }

    // When there are filtered/sorted ingresses and there is a filter/sorting term set generate the sorted/filtered ingresses
    return hasFilters
      ? getSortedIngresses(sliceListByPage(filteredSortedIngresses, currentPage, perPage), generateIngressRow)
      : sliceListByPage(allSortedByStatus, currentPage, perPage).map(generateIngressRow)
  }

  const getSortedIngresses = (filteredIngresses: IngressWithHost[], generateIngressRow: (cert: IngressWithHost) => JSX.Element) =>
    filteredIngresses.length > 0 ? (
      filteredIngresses.map(generateIngressRow)
    ) : (
      <div className={classes.errorContainer}>
        <Typography>No ingresses found with this filter.</Typography>
      </div>
    )

  const extractStatistics = (data: IngressWithHost[]) => {
    let high = 0,
      medium = 0,
      low = 0

    data.forEach(({ resource: { rule_messages } }) => {
      if (rule_messages.length > 0) {
        if (rule_messages.some(x => x.rule.severity === 3 || x.rule.id === "ingress_tls_missing")) {
          high++
        } else if (rule_messages.some(x => x.rule.severity === 2)) {
          medium++
        } else if (rule_messages.some(x => x.rule.severity === 1)) {
          low++
        }
      }
    })
    return {
      high,
      medium,
      low,
    }
  }
  const ingressElements = getIngresses()
  const hasIngresses = Array.isArray(ingressElements) && ingressElements.length > 0

  return (
    <div className={classes.root}>
      {isLoading ? (
        <LoadingMessage label={"loading ingresses..."} />
      ) : error ? (
        <div className={classes.error}>
          <ErrorMessage message={error} />
        </div>
      ) : (
        <>
          <Grid item className={classes.status}>
            <StatusBar
              statusList={[
                {
                  status: "error",
                  message: "High severity ingresses",
                  value: statistics.high,
                  description:
                    "A high severity indicates there are serious issues with your ingress, which require immediate attention and action.",
                },
                {
                  status: "warningMedium",
                  message: "Medium severity ingresses",
                  value: statistics.medium,
                  description: "A medium severity indicates there are important issues, that require your attention.",
                },
                {
                  status: "warning",
                  message: "Low severity ingresses",
                  value: statistics.low,
                  description: "A low severity indicates general warnings that you may or may not act on them. ",
                },
              ]}
              staticStats={[{ label: "Total ingress hosts", value: ingresses.length }]}
            />
          </Grid>
          <FiltersBar filtersState={filtersState} filterOptions={filterOpts} onFilter={onFilter} />
          <div>
            {ingresses.length > 0 && hasIngresses ? (
              <Table>
                <TableHeader
                  rows={[{ label: "", size: 1.5 }, { label: "Host name" }, { label: "Ingress name" }, { label: "Status", size: 3.75 }]}
                />
                <tbody>{ingressElements}</tbody>
              </Table>
            ) : (
              ingressElements
            )}
          </div>
          {totalPages > 0 && hasIngresses && (
            <div className={classes.paginationContainer}>
              <Pagination count={totalPages} page={currentPage} onChange={(_, value) => setCurrentPage(value)} />
            </div>
          )}
          {ingresses.length < 1 && !hasIngresses && (
            <div className={classes.errorContainer}>
              <Typography>No ingresses found in this cluster.</Typography>
            </div>
          )}
        </>
      )}
    </div>
  )
}
