import {
  ReceiverActions,
  CREATE_RECEIVER,
  CREATE_RECEIVER_FAILED,
  CREATE_RECEIVER_SUCCESS,
  FETCH_RECEIVERS,
  FETCH_RECEIVERS_SUCCESS,
  FETCH_RECEIVERS_FAILED,
  UPDATE_RECEIVER,
  UPDATE_RECEIVER_FAILED,
  UPDATE_RECEIVER_SUCCESS,
  DELETE_RECEIVER,
  DELETE_RECEIVER_FAILED,
  DELETE_RECEIVER_SUCCESS,
} from "../actions/receivers"

import { UpdateCurrentOrgAction, UPDATE_CURRENT_ORG } from "@actions/orgs"

import { LOGOUT_FINISHED, LogoutFinishedAction } from "@actions/auth"

export type ReceiversState = {
  receivers: {
    [key: string]: {
      isLoading: boolean
      loadingError: string
      receiversData?: Receiver[]
    }
  }
}

const initialState = {
  receivers: {},
}

export function receiversReducer(
  state: ReceiversState = initialState,
  action: ReceiverActions | LogoutFinishedAction | UpdateCurrentOrgAction,
): ReceiversState {
  switch (action.type) {
    case CREATE_RECEIVER: {
      return {
        ...state,
        receivers: {
          ...state.receivers,
          [`${action.orgID}/${action.clusterID}`]: {
            ...state.receivers[`${action.orgID}/${action.clusterID}`],
            isLoading: true,
            loadingError: "",
          },
        },
      }
    }

    case CREATE_RECEIVER_FAILED: {
      return {
        ...state,
        receivers: {
          ...state.receivers,
          [`${action.orgID}/${action.clusterID}`]: {
            ...state.receivers[`${action.orgID}/${action.clusterID}`],
            isLoading: false,
            loadingError: action.errorMessage,
          },
        },
      }
    }

    case CREATE_RECEIVER_SUCCESS: {
      const receiversData = state.receivers[`${action.orgID}/${action.clusterID}`]
        ? state.receivers[`${action.orgID}/${action.clusterID}`]?.receiversData
        : undefined

      return {
        ...state,
        receivers: {
          ...state.receivers,
          [`${action.orgID}/${action.clusterID}`]: {
            ...state.receivers[`${action.orgID}/${action.clusterID}`],
            receiversData: receiversData ? [...receiversData, action.receiver] : [action.receiver],
            isLoading: false,
            loadingError: "",
          },
        },
      }
    }

    case UPDATE_RECEIVER: {
      return {
        ...state,
        receivers: {
          ...state.receivers,
          [`${action.orgID}/${action.clusterID}`]: {
            ...state.receivers[`${action.orgID}/${action.clusterID}`],
            isLoading: true,
            loadingError: "",
          },
        },
      }
    }

    case UPDATE_RECEIVER_FAILED: {
      return {
        ...state,
        receivers: {
          ...state.receivers,
          [`${action.orgID}/${action.clusterID}`]: {
            ...state.receivers[`${action.orgID}/${action.clusterID}`],
            isLoading: false,
            loadingError: action.errorMessage,
          },
        },
      }
    }

    case UPDATE_RECEIVER_SUCCESS: {
      const receiversData = state.receivers[`${action.orgID}/${action.clusterID}`]
        ? state.receivers[`${action.orgID}/${action.clusterID}`]?.receiversData
        : undefined

      const filteredOutReceivers = receiversData ? receiversData?.filter(({ id }) => id !== action.receiver.id) : []
      return {
        ...state,
        receivers: {
          ...state.receivers,
          [`${action.orgID}/${action.clusterID}`]: {
            ...state.receivers[`${action.orgID}/${action.clusterID}`],
            receiversData: receiversData ? [...filteredOutReceivers, action.receiver] : [action.receiver],
            isLoading: false,
            loadingError: "",
          },
        },
      }
    }

    case DELETE_RECEIVER: {
      return {
        ...state,
        receivers: {
          ...state.receivers,
          [`${action.orgID}/${action.clusterID}`]: {
            ...state.receivers[`${action.orgID}/${action.clusterID}`],
            isLoading: true,
            loadingError: "",
          },
        },
      }
    }

    case DELETE_RECEIVER_FAILED: {
      return {
        ...state,
        receivers: {
          ...state.receivers,
          [`${action.orgID}/${action.clusterID}`]: {
            ...state.receivers[`${action.orgID}/${action.clusterID}`],
            isLoading: false,
            loadingError: action.errorMessage,
          },
        },
      }
    }

    case DELETE_RECEIVER_SUCCESS: {
      const receiversData = state.receivers[`${action.orgID}/${action.clusterID}`]
        ? state.receivers[`${action.orgID}/${action.clusterID}`]?.receiversData
        : undefined

      const filteredOutReceivers = receiversData ? receiversData?.filter(({ id }) => id !== action.receiver.id) : []

      return {
        ...state,
        receivers: {
          ...state.receivers,
          [`${action.orgID}/${action.clusterID}`]: {
            ...state.receivers[`${action.orgID}/${action.clusterID}`],
            receiversData: filteredOutReceivers,
            isLoading: false,
            loadingError: "",
          },
        },
      }
    }

    case FETCH_RECEIVERS: {
      return {
        ...state,
        receivers: {
          ...state.receivers,
          [`${action.orgID}/${action.clusterID}`]: {
            ...state.receivers[`${action.orgID}/${action.clusterID}`],
            isLoading: true,
            loadingError: "",
          },
        },
      }
    }

    case FETCH_RECEIVERS_FAILED: {
      return {
        ...state,
        receivers: {
          ...state.receivers,
          [`${action.orgID}/${action.clusterID}`]: {
            ...state.receivers[`${action.orgID}/${action.clusterID}`],
            isLoading: false,
            loadingError: action.errorMessage,
          },
        },
      }
    }

    case FETCH_RECEIVERS_SUCCESS: {
      return {
        ...state,
        receivers: {
          ...state.receivers,
          [`${action.orgID}/${action.clusterID}`]: {
            ...state.receivers[`${action.orgID}/${action.clusterID}`],
            receiversData: action.receivers,
            isLoading: false,
            loadingError: "",
          },
        },
      }
    }

    case LOGOUT_FINISHED:
    case UPDATE_CURRENT_ORG: {
      return { ...initialState }
    }

    default:
      return state
  }
}
