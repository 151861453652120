import { ClusterData, RuleMessage, ResourceStatus } from "../types"
import { Resource } from "./resource"
import * as cm from "../cert-manager"
import * as external from "../external"

export function calculateIssuers(data: ClusterData, namespaces?: string[]): Issuer[] {
  const issuerIDs = data?.kind_index["cert-manager.io/v1/Issuer"] || []
  const clusterIssuerIDs = data?.kind_index["cert-manager.io/v1/ClusterIssuer"] || []
  const casIssuerIDs = data?.kind_index["cas-issuer.jetstack.io/v1beta1/GoogleCASIssuer"] || []
  const casClusterIssuerIDs = data?.kind_index["cas-issuer.jetstack.io/v1beta1/GoogleCASClusterIssuer"] || []
  const pcaIssuerIDs = data?.kind_index["awspca.cert-manager.io/v1beta1/AWSPCAIssuer"] || []
  const pcaClusterIssuerIDs = data?.kind_index["awspca.cert-manager.io/v1beta1/AWSPCAClusterIssuer"] || []

  let allIssuersIDs = issuerIDs.concat(clusterIssuerIDs, casIssuerIDs, casClusterIssuerIDs, pcaIssuerIDs, pcaClusterIssuerIDs)

  if (namespaces && namespaces.length > 0) {
    allIssuersIDs = allIssuersIDs.filter(issuerID => namespaces.includes(data.resource_index[issuerID].resource.metadata?.namespace || ""))
  }
  // only return non deleted issuers
  allIssuersIDs = allIssuersIDs?.filter(issuerID => !data.resource_index[issuerID].deleted_at)
  return allIssuersIDs?.map(issuerID => new Issuer(data, issuerID)) || []
}

const allIssuers = [
  cm.Issuers.ClusterIssuer.valueOf(),
  cm.Issuers.Issuer.valueOf(),
  external.Issuers.GoogleCASIssuer.valueOf(),
  external.Issuers.GoogleCASClusterIssuer.valueOf(),
  external.Issuers.AWSPCAIssuer.valueOf(),
  external.Issuers.AWSPCAClusterIssuer.valueOf(),
]

export class Issuer extends Resource {
  id: string
  resource:
    | cm.Issuer
    | cm.ClusterIssuer
    | external.GoogleCasIssuer
    | external.GoogleCasClusterIssuer
    | external.AwsPcaIssuer
    | external.AwsPcaClusterIssuer
  rule_messages: RuleMessage[]
  status: ResourceStatus
  clusterId: string
  deleted_at?: string
  issuerType: external.IssuerTypes | cm.IssuerTypes

  constructor(data: ClusterData, id: string) {
    super()
    const item = data.resource_index[id]
    this.id = id
    this.issuerType = cm.IssuerTypes.internal

    switch (item.resource?.kind) {
      case cm.Issuers.ClusterIssuer:
        this.resource = item.resource as cm.ClusterIssuer
        break
      case external.Issuers.GoogleCASIssuer:
        this.resource = item.resource as external.GoogleCasIssuer
        this.issuerType = external.IssuerTypes.GoogleCas
        break
      case external.Issuers.GoogleCASClusterIssuer:
        this.resource = item.resource as external.GoogleCasClusterIssuer
        this.issuerType = external.IssuerTypes.GoogleCas
        break
      case external.Issuers.AWSPCAIssuer:
        this.resource = item.resource as external.AwsPcaIssuer
        this.issuerType = external.IssuerTypes.AwsPca
        break
      case external.Issuers.AWSPCAClusterIssuer:
        this.resource = item.resource as external.AwsPcaClusterIssuer
        this.issuerType = external.IssuerTypes.AwsPca
        break
      default:
        this.resource = item.resource as cm.Issuer
        break
    }
    this.rule_messages = item.rule_messages
    this.status = item.status ? item.status : { type: "unknown", message: "Unknown" }
    this.deleted_at = item.deleted_at
    this.clusterId = data.cluster_id
  }
}

export function isAllowedIssuer(kind = "") {
  return allIssuers.includes(kind)
}
