import React from "react"
import { Route, Routes } from "react-router-dom"
import CookieConsent from "react-cookie-consent"
import { getCookieConsentValue } from "react-cookie-consent"
import { createTheme, ThemeProvider, Button, createStyles, makeStyles, Theme } from "@material-ui/core"
import { useSelector } from "react-redux"
import {
  HOME_PATH,
  LOGIN_PATH,
  USER_PROFILE_PATH,
  MANAGE_TEAM_PATH,
  TOS_PATH,
  FORCE_ERROR_PATH,
  routePaths,
  AWS_LANDING,
  AWS_LINK_ORGANIZATION,
  SUPPORT_PATH,
} from "@routes"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { appConfigSelector } from "@selectors/appConfig"
import { AppConfigAnalytics } from "@lib/appConfig"

import { Welcome } from "@components/Welcome"
import { NotFound } from "@components/NotFound"
import { Login } from "@components/Login"
import { AddClusterWizard } from "@components/Wizards/AddClusterWizard"
import { AddIssuerWizard } from "@components/Wizards/AddIssuerWizard"
import { UpdateAgentWizard } from "@components/Wizards/UpdateAgentWizard"
import { UserProfile } from "@components/UserProfile"
import { ManageTeam } from "@components/OrganizationSettings/ManageTeam"
import { Tos } from "@components/Tos"
import { ForceError } from "@components/ForceError"
import { StyleGuide } from "@components/StyleGuide"
import { CertInventory } from "@components/CertInventory"
import { CertInventoryMultiCluster } from "@components/CertInventoryMultiCluster"
import { OrganizationOverview } from "@components/OrganizationOverview"
import { ErrorBoundary } from "@components/ErrorBoundary"
import { AWSLanding } from "@components/AWSLanding"
import { AWSLinkOrganization } from "@components/AWSLinkOrganization"
import { OrganizationSettings } from "@components/OrganizationSettings"
import { OrganizationSummaries } from "@components/OrganizationSummaries"
import { Support } from "@components/Support"
import { ConnectCluster } from "@components/Wizards/ConnectClusterWizard"

import { GlobalStyles } from "../../globalStyles"
import { ProtectedRoute } from "@components/ProtectedRoute"

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    nudge: Palette["primary"]
    selected: Palette["primary"]
  }
  interface PaletteOptions {
    nudge?: PaletteOptions["primary"]
    selected?: PaletteOptions["primary"]
  }
}

const theme = createTheme({
  // Override the breakpoints values so that md
  // matches up with our css breakpoint of 768
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  // These colors should be synced with ui/src/theme.css
  palette: {
    primary: {
      main: "hsl(14, 100%, 60%)",
      light: "hsl(14, 100%, 70%)",
      dark: "hsl(14, 100%, 40%)",
    },
    secondary: {
      main: "hsl(185, 71%, 50%)",
      light: "hsl(185, 71%, 60%)",
      dark: "hsl(185, 71%, 40%)",
    },
    error: {
      main: "hsl(354, 84%, 51%)",
    },
    warning: {
      main: "hsl(43, 100%, 60%)",
      light: "hsl(43, 100%, 71%)",
      dark: "hsl(29, 100%, 50%)",
    },
    success: {
      main: "hsl(127, 50%, 50%)",
    },
    nudge: {
      main: "hsl(48, 86%, 50%)",
    },
    selected: {
      main: "hsl(260, 23%, 96%)",
      dark: "hsl(260, 30%, 78%)",
    },
    common: {
      black: "hsl(238, 23%, 31%)",
    },
    background: {
      default: "#f4f5f7",
    },
  },
  typography: {
    fontFamily: "sofia-pro,sans-serif",
  },
  props: {
    MuiLink: {
      color: "primary",
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: "#fff",
      },
    },
    MuiChip: {
      label: {
        // Fix descenders being chopped off with sofia-pro
        height: "1rem",
      },
    },
  },
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: "#f4f5f7",
    },
    cookieBar: {
      background: `${theme.palette.common.black} !important`,
      padding: theme.spacing(1, 2),
      display: "flex",
      alignItems: "center !important" /* Needed to override inline styles generated by react-cookie-consent */,
      lineHeight: 1.45,
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    cookieButtons: {
      margin: theme.spacing(0, 2, 2),
      [theme.breakpoints.up(768)]: {
        margin: theme.spacing(1, 1, 1, 0),
      },
    },
    cookieAccept: {},
    cookieDecline: {
      marginRight: theme.spacing(2),
    },
  }),
)

type AppProps = {
  // Enable tracking and analytics plugins when the user consents
  enableAnalytics: (config?: AppConfigAnalytics) => void
}

export const App = ({ enableAnalytics }: AppProps) => {
  const classes = useStyles()
  const { appConfig } = useSelector(appConfigSelector)
  const optionalCookiesEnabled = getCookieConsentValue() === "true"

  if (optionalCookiesEnabled) {
    enableAnalytics(appConfig?.analytics)
  }

  // Create a reactQuery client
  const queryClient = new QueryClient()

  return (
    // Provide the client to your App
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <div className={classes.root}>
          <ErrorBoundary>
            <Routes>
              <Route path={HOME_PATH} element={<Welcome />} />
              {/* docs paths are based on the content in the docs component, all docs paths go here */}
              <Route path={LOGIN_PATH} element={<Login />} />
              <Route path={TOS_PATH} element={<Tos />} />
              {/* ForceError will allow us to test the error tracking integrations end to end in production. */}
              <Route path={FORCE_ERROR_PATH} element={<ForceError />} />
              <Route path={SUPPORT_PATH} element={<ProtectedRoute path={SUPPORT_PATH} element={<Support />} />} />
              <Route path="/__styleguide" element={<StyleGuide />} />

              <Route
                path={routePaths.orgOverview}
                element={
                  <>
                    <ProtectedRoute path={routePaths.orgOverview} element={<OrganizationOverview />} />
                  </>
                }
              />

              {/* <Route path="/org/+current/*" element={<CurrentOrg />} /> */}
              <Route path={USER_PROFILE_PATH} element={<ProtectedRoute path={USER_PROFILE_PATH} element={<UserProfile />} />} />
              <Route path={MANAGE_TEAM_PATH} element={<ProtectedRoute path={MANAGE_TEAM_PATH} element={<ManageTeam />} />} />
              <Route
                path={routePaths.newCluster}
                element={<ProtectedRoute path={routePaths.newCluster} element={<AddClusterWizard />} />}
              />
              <Route
                path={routePaths.newClusterV2}
                element={<ProtectedRoute path={routePaths.newClusterV2} element={<ConnectCluster />} />}
              />
              <Route path={routePaths.newIssuer} element={<ProtectedRoute path={routePaths.newIssuer} element={<AddIssuerWizard />} />} />
              <Route
                path={routePaths.updateAgent}
                element={<ProtectedRoute path={routePaths.updateAgent} element={<UpdateAgentWizard />} />}
              />
              <Route
                path={routePaths.allResources}
                element={
                  <>
                    <ProtectedRoute path={routePaths.allResources} element={<CertInventory allResourcesView={true} />} />
                  </>
                }
              />
              <Route
                path={routePaths.certInventoryCluster}
                element={<ProtectedRoute path={routePaths.certInventoryCluster} element={<CertInventory />} />}
              />
              <Route
                path={routePaths.certInventoryClusterParam}
                element={<ProtectedRoute path={routePaths.certInventoryClusterParam} element={<CertInventory />} />}
              />
              <Route
                path={routePaths.certInventory}
                element={<ProtectedRoute path={routePaths.certInventory} element={<CertInventoryMultiCluster />} />}
              />

              <Route
                path={routePaths.orgSummaries}
                element={
                  <>
                    <ProtectedRoute path={routePaths.orgSummaries} element={<OrganizationSummaries />} />
                  </>
                }
              />
              <Route
                path={routePaths.orgOverview}
                element={
                  <>
                    <ProtectedRoute path={routePaths.orgOverview} element={<OrganizationOverview />} />
                  </>
                }
              />
              <Route
                path={routePaths.orgSettings}
                element={
                  <>
                    <ProtectedRoute path={routePaths.orgSettings} element={<OrganizationSettings />} />
                  </>
                }
              />
              <Route
                path={routePaths.orgRoot}
                element={
                  <>
                    <ProtectedRoute path={routePaths.orgSettings} element={<OrganizationSettings />} />
                  </>
                }
              />

              {/* AWS Marketplace onboarding routes */}
              <Route path={AWS_LANDING} element={<AWSLanding />} />
              <Route
                path={AWS_LINK_ORGANIZATION}
                element={
                  <>
                    <ProtectedRoute path={AWS_LINK_ORGANIZATION} element={<AWSLinkOrganization />} />
                  </>
                }
              />

              <Route element={<NotFound />} />
            </Routes>
            <CookieConsent
              onAccept={() => {
                enableAnalytics(appConfig?.analytics)
              }}
              buttonText="Accept"
              declineButtonText="Decline"
              enableDeclineButton
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              ButtonComponent={(props: any) => <Button variant="contained" color="primary" {...props} />}
              containerClasses={classes.cookieBar}
              // "CookiesAccept" is used in testrunner, as it is not possible to add datatest-id to the accept button.
              buttonClasses={classes.cookieAccept + " CookiesAccept"}
              declineButtonClasses={classes.cookieDecline}
              buttonWrapperClasses={classes.cookieButtons}
              disableButtonStyles
              style={{ zIndex: 1500 }}
            >
              We use necessary cookies to make our site work. We'd also like to set optional analytics cookies to help us improve it. We
              won't set optional cookies unless you enable them. Using this tool will set a cookie on your device to remember your
              preferences.
            </CookieConsent>
          </ErrorBoundary>
        </div>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
