import React from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Paper, Button, createStyles, makeStyles, Theme } from "@material-ui/core"

import { genAgentCommand } from "../common"
import { getOrgID, getClusterID } from "@routes"

import { Page } from "@components/Page"
import { Expandable } from "@components/Expandable"
import { Snippet } from "@components/Snippet"
import { appConfigSelector } from "@selectors/appConfig"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    wizards: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    step: {
      margin: theme.spacing(2, "auto"),
      padding: theme.spacing(2.5),
      "& ul": {
        listStyleType: "disc",
        listStylePosition: "inside",
      },
    },
    stepTitle: {
      fontSize: "1.5rem",
      marginBottom: theme.spacing(2),
      "& p": {
        margin: theme.spacing(2, 0),
      },
    },
    spinner: {
      marginTop: theme.spacing(6),
    },
    button: {
      margin: theme.spacing(4, 0, 0),
      display: "flex",
      justifyContent: "center",
    },
  })
})
const configurationStep = (orgID: string, clusterName: string) => {
  const classes = useStyles()
  const { appConfig } = useSelector(appConfigSelector)
  const command = genAgentCommand(orgID, clusterName, null, !!appConfig?.agent_installation.disable_auth, false)

  return (
    <Paper className={classes.step}>
      <h2 className={classes.stepTitle}>Update agent configuration</h2>
      <p>Before updating the agent, we need to update its configuration.</p>
      <p>The agent will gather data from your infrastructure and send it back to Jetstack Secure, to do this it needs the following:</p>
      <ul>
        <li>A configuration to instruct the agent what data to gather</li>
        <li>Kubernetes credentials and permissions to gather resources</li>
        <li>Credentials to post that data back to Jetstack Secure</li>
      </ul>
      <p>To update this, you just need to have kubectl configured with the context of your cluster enabled.</p>
      <p>
        Copy the provided command and run it in your terminal. Please take the time to review the config when running in real environments
        and make changes to the config and permissions where required.
      </p>
      <div className={classes.button}>
        <Button variant="contained" color="primary" onClick={() => navigator.clipboard.writeText(command)}>
          Copy command to clipboard
        </Button>
      </div>
      <Expandable title="You can also inspect the command.">
        <Snippet showLineNumbers text={command} language="shell" />
      </Expandable>
      <p>
        Running with this default configuration, the agent will supply data to support recommendations on your configuration of pods,
        cert-manager resources, and versions of other commonly used Kubernetes applications.
      </p>
    </Paper>
  )
}

const updateAgentStep = () => {
  const classes = useStyles()
  const command = `kubectl apply -f ${window.location.protocol}//${window.location.host}/install/agent.yaml`

  return (
    <Paper className={classes.step}>
      <h2 className={classes.stepTitle}>Update the agent</h2>
      <p>With the config in place, we can now update the agent.</p>
      <p>Update the agent with the following command:</p>
      <Snippet text={command} language="shell" />
    </Paper>
  )
}

export const UpdateAgentWizard = () => {
  const classes = useStyles()
  const orgID = getOrgID(useParams())
  const clusterName = getClusterID(useParams())

  return (
    <Page title="Update Jetstack Secure Agent" reqAuth>
      <div className={classes.wizards}>
        <ul>
          <li>{configurationStep(orgID, clusterName)}</li>
          <li>{updateAgentStep()}</li>
        </ul>
      </div>
    </Page>
  )
}
