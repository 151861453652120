import React, { useEffect, useRef, useState, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import ReactGA from "react-ga"
import * as queryString from "query-string"
import { formatDistanceToNow } from "date-fns"
import { isEmpty } from "lodash"
import { createStyles, makeStyles, Theme, Paper, Button } from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"
import { settingsSelector } from "@selectors/settings"
import { FeatureCertInventory } from "@utils/features"
import { checkIsCertInventoryLoading, collateAllResources, CollatedResources } from "@utils/certificate"
import { Resource } from "@lib/certInventory/resources"
import { ClusterData } from "@lib/certInventory"
import { fetchClusters } from "@actions/clusters"
import { fetchReceivers } from "@actions/receivers"
import { configDemoCluster } from "@actions/settings"
import { featuresSelector } from "@selectors/features"
import { clustersNetworkSelector } from "@selectors/clustersNetwork"
import { clustersWithCertInventorySelector } from "@selectors/clusters"
import { fetchCertInventory } from "@actions/certInventory"
import { certInventorySelector } from "@selectors/certInventory"
import { filterSelector, FilterType } from "@selectors/filters"
import { updateFilters } from "@actions/filters"
import {
  getOrgID,
  getClusterID,
  getClusterView,
  HOME_PATH,
  pathToNewCluster,
  pathToCertInventory,
  pathToCertInventoryCluster,
  pathToAllResources,
} from "@routes"
import { useSupportsMultiClusterSelector } from "@hooks/useSupportsMultiCluster"
import { useGetAllCertInventories } from "@hooks/useGetAllCertInventories"
import { useGetClustersData } from "@lib/clusters"
import { AddClusterOption } from "@components/CertInventoryMultiCluster/AddClusterOption"
import { Page } from "@components/Page"
import { ErrorMessage } from "@components/ErrorMessage"
import { LoadingMessage } from "@components/LoadingMessage"
import { Breadcrumbs, BreadcrumbsNavItem } from "@components/Navigation/Breadcrumbs"
import { DemoTour } from "@components/Onboarding/DemoTour"
import { FilterProps } from "@components/FiltersBar"
import { Certificates } from "./Certificates"
import { Issuers } from "./Issuers"
import { Ingresses } from "./Ingresses"
import { DetailsPanel } from "./DetailsPanel"
import { ClusterDropdown } from "./ClusterDropdown"
import { AlertSetup } from "./AlertSetup"
import { ClusterStatus } from "./ClusterStatus"
import { NavBar } from "./NavBar"
import { DeleteCluster } from "./DeleteCluster"
import { CLUSTER_VIEW, tabLinks } from "./constants"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    certInventory: {
      margin: theme.spacing(0, 0, 2, 0),
      padding: theme.spacing(2, 0),
      display: "flex",
    },
    certificatesGrid: {
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.paper,
      margin: "auto",
    },
    error: {
      marginTop: theme.spacing(2),
    },
    clusterSelector: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(2),
      margin: theme.spacing(1, 1, 0, 0),
      float: "right",
    },
    section: {
      margin: theme.spacing(0, 2),
    },
    disabledSection: {
      margin: theme.spacing(0, 2),
      opacity: 0.3,
      pointerEvents: "none",
    },
    clusterContainer: {
      display: "flex",
      alignItems: "center",
      "& a": {
        marginRight: theme.spacing(2),
      },
    },
    breadCrumbs: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      marginBottom: theme.spacing(2),
    },
    goBack: {
      textAlign: "center",
      margin: theme.spacing(4, 0, 2, 0),
    },
    alert: {
      marginTop: theme.spacing(2),
      border: `1px solid ${theme.palette.info.light}`,
      position: "relative",
      paddingRight: theme.spacing(30),
      [theme.breakpoints.down(700)]: {
        paddingRight: 0,
      },
    },
    tourBtn: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      right: "0.5rem",
      [theme.breakpoints.down(700)]: {
        position: "relative",
        top: "unset",
        right: "unset",
        display: "block",
        transform: "translateY(0)",
        marginTop: theme.spacing(2),
      },
    },
    hideDemoBtn: {
      margin: theme.spacing(0, 2),
    },
  }),
)

// TODO: Split the component up a bit, as it's becoming quite large
type CertInventoryProps = {
  allResourcesView?: boolean
}

export const CertInventory = ({ allResourcesView }: CertInventoryProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { settings } = useSelector(settingsSelector())
  const { clustersData } = useGetClustersData(settings?.show_demo_cluster)
  const [cluster, setCluster] = useState("")
  const [resourceOnDisplay, setResourceOnDisplay] = useState<Resource | undefined>(undefined)
  const [startFlow, setStartFlow] = useState(false)
  const [clusterData, setClusterData] = useState<ClusterData | undefined>(undefined)
  const [clusterIsLoading, setClusterIsLoading] = useState(false)
  const [clusterError, setClusterError] = useState("")

  const params = useParams<{ orgId: string; clusterID: string; clusterView: string }>()
  const features = useSelector(featuresSelector)
  const clusters = useSelector(clustersWithCertInventorySelector)
  const orgID = getOrgID(params)
  const clusterIDFromURL = getClusterID(params)
  const resourceView = (getClusterView(params) as FilterType) || CLUSTER_VIEW.CERTIFICATES // default to Certificates view
  const { isLoading, loadingError } = useSelector(clustersNetworkSelector)
  const certInventory = useSelector(certInventorySelector)
  const { certInventory: allCertInventories } = useGetAllCertInventories(!allResourcesView)
  const { supportsMultiCluster, clusterLimit } = useSupportsMultiClusterSelector()
  const filtersState = useSelector(filterSelector(resourceView))

  const isLoadingAll =
    isLoading || clusterIsLoading || (!!allResourcesView && !isEmpty(allCertInventories) && checkIsCertInventoryLoading(allCertInventories))

  let allCollatedResources: CollatedResources = {
    certManagerCertificates: [],
    nonCertManagerCertificates: [],
    ephemeralCertificates: [],
    certificateRequests: [],
    issuers: [],
    ingresses: [],
    routes: [],
  }
  if (!isLoadingAll) {
    allCollatedResources = collateAllResources(allCertInventories)
  }

  const currentCluster = useMemo(
    () => clustersData.clusters.find(cluster => cluster.id === clusterIDFromURL),
    [clustersData, clusterIDFromURL],
  )

  const isClusterActive = currentCluster?.active

  useEffect(() => {
    if (!features?.includes(FeatureCertInventory)) {
      navigate(HOME_PATH)
    }
  }, [features, navigate])

  const dispatchFilter = (filter: FilterProps) => dispatch(updateFilters({ [resourceView]: filter }))
  const firstUpdate = useRef(true)

  useEffect(() => {
    const queryFiltersFromUrl = queryString.parse(location.search)
    const url = new URL(document.URL)
    const urlHash = url.hash !== undefined ? url.hash : ""
    const hasQueryFilters = !isEmpty(queryFiltersFromUrl)
    const hasFiltersState = !isEmpty(filtersState)
    const queryFromState = queryString.stringify(filtersState)
    const cluster = clusterIDFromURL ? clusterIDFromURL : clusters[0]?.cluster

    if (!hasQueryFilters && !hasFiltersState) {
      return
    } else if (hasQueryFilters && !hasFiltersState) {
      firstUpdate.current && dispatchFilter(queryFiltersFromUrl)
      updateURL(resourceView, cluster, urlHash, firstUpdate.current ? queryString.stringify(queryFiltersFromUrl) : "")
    } else if (!hasQueryFilters && hasFiltersState) {
      updateURL(resourceView, cluster, urlHash, queryFromState)
    } else if (hasQueryFilters && hasFiltersState) {
      updateURL(resourceView, cluster, urlHash, queryFromState)
    }
    firstUpdate.current = false
  }, [history, resourceView, filtersState, clusterIDFromURL])

  // effect that fetches cluster list if orgID changes
  useEffect(() => {
    dispatch(fetchClusters(orgID))
  }, [dispatch, orgID])

  useEffect(() => {
    if (orgID && cluster) {
      dispatch(fetchReceivers(orgID, cluster))
    }
  }, [orgID, cluster])

  // effect that sets the URL to the first cluster on the list, if no cluster provided in the URL
  useEffect(() => {
    // only set the URL to the first cluster when there is only one cluster
    if (clusters.length === 1 && clusters[0].cluster !== clusterIDFromURL && !allResourcesView) {
      // updateURL(resourceView, clusters[0].cluster, undefined)
    }
  }, [clusters, clusterIDFromURL, resourceView])

  useEffect(() => {
    if (allResourcesView) {
      return
    }

    // sets cluster in URL as active cluster
    if (!clusterIDFromURL) {
      setCluster(clusters[0].cluster)
    } else {
      setCluster(clusterIDFromURL)
    }
    // when returning to cert-inventory top level view, unset the clusterID
  }, [clusterIDFromURL, resourceView, filtersState])

  // effect that fetches CertInventory data for the cluster
  useEffect(() => {
    if (cluster && cluster !== "") {
      const certInventoryForCluster = certInventory[cluster]
      const isRefetchIfNoCluster =
        !certInventoryForCluster ||
        (!certInventoryForCluster.certInventory && !certInventoryForCluster.isLoading && certInventoryForCluster.loadingError === "")
      if (isRefetchIfNoCluster) {
        if (clusters.length > 0) {
          const isDemoCluster = clusters.some(c => c.cluster === cluster && c.isDemoData)
          dispatch(fetchCertInventory(orgID, cluster, isDemoCluster))
        }
      } else {
        setClusterData(certInventoryForCluster.certInventory)
        setClusterIsLoading(certInventoryForCluster.isLoading)
        setClusterError(certInventoryForCluster.loadingError)
      }
    }
  }, [dispatch, orgID, cluster, certInventory, clusters])

  // if demo cluster, then send GA event
  useEffect(() => {
    if (clusterData && clusterData.is_demo_data) {
      ReactGA.event({
        category: "DemoCluster",
        action: "Seen demo cluster details page",
      })
    }
  }, [clusterData])

  const updateURL = (view: string, clusterID = "", hash = "", query = "") => {
    if (allResourcesView) {
      navigate(`${pathToAllResources(orgID, view)}${query ? "?" + query : ""}${hash}`)
    } else {
      navigate(`${pathToCertInventoryCluster(orgID, clusterID, view)}${query ? "?" + query : ""}${hash}`)
    }
  }

  const getURLHash = (): string | undefined => {
    const url = new URL(document.URL)
    if (url.hash && url.hash !== "" && url.hash !== "#") {
      return url.hash.replace("#", "")
    }
    return undefined
  }

  const handleClusterChange = (cluster: string) => {
    if (cluster) {
      navigate(pathToCertInventoryCluster(orgID, cluster, resourceView))
    }
  }

  const handleClose = () => {
    const query = queryString.stringify(filtersState)
    updateURL(resourceView, cluster, "", query)
    setResourceOnDisplay(undefined)
    document.getElementsByTagName("body")[0].style.overflow = "auto"
  }

  const handleSelect = (res: Resource, hostName = "") => {
    if (res.hash() !== resourceOnDisplay?.hash()) {
      const isIngress = resourceView === CLUSTER_VIEW.INGRESSES && hostName
      const hash = res.hash()
      const generatedHash = isIngress ? `#${hash}#${hostName}` : `#${hash}`
      const query = queryString.stringify(filtersState)

      updateURL(resourceView, res.clusterId, generatedHash, query)
      setResourceOnDisplay(res)
      document.getElementsByTagName("body")[0].style.overflow = "hidden"
    }
  }

  const onChangeView = (view: string) => {
    updateURL(view, cluster, "")
  }

  const handleTour = () => {
    navigate(pathToCertInventoryCluster(orgID, clusterIDFromURL, CLUSTER_VIEW.CERTIFICATES))
    setStartFlow(true)
  }

  const hideDemoCluster = () => {
    dispatch(configDemoCluster(orgID, false))
    navigate(pathToCertInventory(orgID))
  }

  const renderContent = () => {
    return (
      <div data-testid="cert-inventory-content" className={classes.certInventory}>
        <div className={classes.certificatesGrid}>
          <Paper className={classes.paper}>
            {isLoading ? (
              <LoadingMessage label="loading..." />
            ) : loadingError ? (
              <div className={classes.error}>
                <ErrorMessage message={loadingError} />
              </div>
            ) : (
              <>
                {resourceView === CLUSTER_VIEW.CERTIFICATES && (
                  <div className={classes.section} data-tut="certificates">
                    <Certificates
                      data={clusterData}
                      isLoading={isLoadingAll}
                      error={clusterError}
                      handleSelect={handleSelect}
                      activeResource={resourceOnDisplay}
                      activeResourceHash={getURLHash()}
                      cluster={clusterIDFromURL}
                      allResourcesView={allResourcesView}
                      allClustersResources={allCollatedResources}
                    />
                  </div>
                )}

                {resourceView === CLUSTER_VIEW.ISSUERS && (
                  <div className={classes.section} data-tut="issuers">
                    <Issuers
                      data={clusterData}
                      isLoading={isLoadingAll}
                      error={clusterError}
                      handleSelect={handleSelect}
                      activeResource={resourceOnDisplay}
                      activeResourceHash={getURLHash()}
                      allResourcesView={allResourcesView}
                      allClustersResources={allCollatedResources}
                    />
                  </div>
                )}

                {resourceView === CLUSTER_VIEW.INGRESSES && (
                  <div className={classes.section} data-tut="ingresses">
                    <Ingresses
                      data={clusterData}
                      isLoading={isLoadingAll}
                      error={clusterError}
                      handleSelect={handleSelect}
                      activeResource={resourceOnDisplay}
                      activeResourceHash={getURLHash()}
                      cluster={clusterIDFromURL}
                      allResourcesView={allResourcesView}
                      allClustersResources={allCollatedResources}
                    />
                  </div>
                )}
              </>
            )}
            {certInventory[cluster]?.loadingError && (
              <div className={classes.goBack}>
                <Button variant="contained" color="primary" onClick={() => navigate(pathToCertInventory(orgID))}>
                  Go back to Clusters page
                </Button>
              </div>
            )}
          </Paper>
        </div>
      </div>
    )
  }

  const renderSidePanel = () => {
    if (resourceOnDisplay && (certInventory[cluster] || allResourcesView)) {
      return (
        <DetailsPanel
          isLoading={!allResourcesView && certInventory[cluster].isLoading}
          error={allResourcesView ? "" : certInventory[cluster].loadingError}
          resource={resourceOnDisplay}
          handleSelect={handleSelect}
          onClose={handleClose}
          selectedHostname={getURLHash()?.split("#")[1]}
          showClusterName={allResourcesView}
        />
      )
    }
    return undefined
  }

  const clustersBreadCrumb = { label: "Clusters", href: pathToCertInventory(orgID) }
  const crumbs: BreadcrumbsNavItem[] = supportsMultiCluster
    ? allResourcesView
      ? ["Organization"]
      : ["Organization", clustersBreadCrumb]
    : [clustersBreadCrumb]

  const renderBreadCrumbs = () => (
    <div className={classes.breadCrumbs}>
      <Breadcrumbs current={allResourcesView ? "Resources" : clusterIDFromURL} parents={crumbs} />
      <div style={{ display: "flex", alignItems: "center", gridGap: "1rem" }}>
        {!allResourcesView &&
          (clusterData?.is_demo_data ? (
            <Button className={classes.hideDemoBtn} variant="contained" color="default" onClick={hideDemoCluster}>
              Hide Demo Cluster
            </Button>
          ) : (
            <DeleteCluster cluster={cluster} organization={orgID} />
          ))}
        <AddClusterOption limitReached={!supportsMultiCluster} redirectPath={pathToNewCluster(orgID)} text="Extend cluster limit" />
      </div>
    </div>
  )

  const clusterDropdown = () => (
    <div className={classes.clusterContainer}>
      <ClusterDropdown clusters={clusters} selectedCluster={cluster} clusterLimit={clusterLimit} onChange={handleClusterChange} />
    </div>
  )

  return (
    <Page
      title={allResourcesView ? "Organization resources" : cluster}
      titleComponent={allResourcesView ? undefined : clusterDropdown()}
      reqAuth
      fixedWidth
      sidePanel={renderSidePanel()}
      titleVariant={allResourcesView ? undefined : "medium-uppercase"}
      breadcrumbs={renderBreadCrumbs()}
      customSideComponent={
        certInventory[cluster]?.certInventory && !allResourcesView ? <ClusterStatus cluster={certInventory[cluster]} /> : undefined
      }
      titleAddonComponent={
        certInventory[cluster]?.certInventory && !allResourcesView ? (
          <>
            <AlertSetup cluster={cluster} />
          </>
        ) : undefined
      }
    >
      {startFlow && !allResourcesView && <DemoTour isOpen={startFlow} onClose={() => setStartFlow(false)} />}
      {!isClusterActive && (
        <MuiAlert className={classes.alert} severity="info">
          {`This cluster is inactive, last data sent was ${
            currentCluster?.agentLastUploadTime
              ? formatDistanceToNow(new Date(currentCluster?.agentLastUploadTime), { includeSeconds: true })
              : ""
          } ago`}
        </MuiAlert>
      )}
      {allResourcesView ? (
        <NavBar selected={resourceView} onChange={onChangeView} links={tabLinks} />
      ) : (
        certInventory[cluster] &&
        !certInventory[cluster].loadingError && (
          <>
            {certInventory[cluster].certInventory?.is_demo_data && (
              <MuiAlert severity="info" className={classes.alert}>
                <div>This is not a real cluster and it's intended for demo purposes</div>
                <Button variant="contained" color="primary" className={classes.tourBtn} onClick={handleTour}>
                  Take a tour
                </Button>
              </MuiAlert>
            )}
            <NavBar selected={resourceView} onChange={onChangeView} links={tabLinks} />
          </>
        )
      )}
      {isLoading ? <LoadingMessage label="Loading clusters" /> : clusters.length > 0 && renderContent()}
    </Page>
  )
}
