import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import * as queryString from "query-string"
import axios from "axios"

import { createStyles, makeStyles, Theme, Button, Link, Typography, FormControl, Select, MenuItem, Snackbar } from "@material-ui/core"
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert"

import { Logo } from "@components/Logo"
import { LoadingMessage } from "@components/LoadingMessage"
import { pathToCertInventory } from "routes"
import { accessTokenSelector, userSelector, organizationsSelector, isFetchingUserSelector } from "@selectors/auth"
import { currentOrgSelector } from "@selectors/orgs"
import { changeUser, fetchAuthInfo } from "@actions/auth"

import { handleError } from "@utils/errorHandling"
import { addAuth } from "@lib/auth"

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      margin: theme.spacing(4),
    },
    frame: {
      padding: theme.spacing(6),
      background: theme.palette.common.black,
      maxWidth: "56rem",
      width: "100%",
      borderRadius: 5,
    },
    logo: {
      maxWidth: "19rem",
      padding: theme.spacing(2, 0, 6, 0),
      display: "inline-block",
    },
    content: {
      color: "#fff",
      fontSize: "1.125rem",
      marginBottom: theme.spacing(2),
    },
    button: {
      fontSize: "1.125rem",
      fontWeight: 500,
      padding: theme.spacing(2, 5),
      lineHeight: 1.4,
      marginRight: theme.spacing(6),

      [theme.breakpoints.down(610)]: {
        width: "100%",
        maxWidth: "20rem",
        margin: theme.spacing(0, 0, 4, 0),
      },
    },
    link: {
      color: "#fff",
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "none",
        cursor: "pointer",
      },
    },
    buttons: {
      margin: theme.spacing(4, 0, 2, 0),
      textAlign: "left",
      [theme.breakpoints.down(768)]: {
        marginTop: 0,
      },
    },
    select: {
      padding: theme.spacing(0, 0),
      margin: theme.spacing(0, 2),
      display: "flex",
      maxWidth: 300,
      backgroundColor: "white",
    },
  }),
)

export const AWSLinkOrganization = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const token = useSelector(accessTokenSelector)
  const user = useSelector(userSelector)
  const organizations = useSelector(organizationsSelector)
  const currentOrgID = useSelector(currentOrgSelector)
  const isLoading = useSelector(isFetchingUserSelector)
  const [selectedOrgID, selectOrgID] = useState("")
  const [customerID, setCustomerID] = useState("")
  const [toastOpen, setToastOpen] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [submitEnabled, setSubmitEnabled] = useState(true)

  // Use customer UI from query
  useEffect(() => {
    const query = queryString.parse(location.search)
    setCustomerID(query.customerID || "")
  }, [history])

  // Set current org in selector
  useEffect(() => {
    if (!!currentOrgID && currentOrgID !== "") {
      selectOrgID(currentOrgID)
    }
  }, [currentOrgID])

  useEffect(() => {
    if (!isLoading && !user) {
      dispatch(fetchAuthInfo(false))
    }
  }, [isLoading, user, organizations, dispatch])

  const handleLink = () => {
    setSubmitEnabled(false)
    axios
      .post(
        `/api/aws_registration/orgs/${selectedOrgID}`,
        {
          customer_id: customerID,
        },
        addAuth(token),
      )
      .then(() => {
        setErrorMsg("")
        setToastOpen(true)
      })
      .catch(err => {
        handleError(err)
        setErrorMsg(err.response.data.error || err.message)
        setToastOpen(true)
        setSubmitEnabled(true)
      })
  }

  const handleOrgSelected = (event: React.ChangeEvent<{ value: unknown }>) => {
    const orgID = event.target.value as string
    selectOrgID(orgID)
  }

  const handleSwitchUser = () => {
    dispatch(changeUser(window.location.pathname + window.location.search))
  }

  const handleToastEnd = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    setToastOpen(false)
    if (errorMsg === "") {
      navigate(pathToCertInventory(selectedOrgID))
    }
  }

  const renderContent = () => {
    if (user) {
      return (
        <>
          <Typography gutterBottom className={classes.content}>
            Welcome {user.name}. You have purchased a Jetstack Secure license using the AWS Marketplace.
          </Typography>
          <Typography gutterBottom className={classes.content}>
            Select the organization you would like to link to Jetstack Secure:
          </Typography>
          <div className={classes.buttons}>
            <FormControl variant="outlined">
              {organizations ? (
                <Select
                  id="organisation-select-label"
                  onChange={handleOrgSelected}
                  value={selectedOrgID}
                  label="Organization"
                  className={classes.select}
                >
                  {organizations.map(org => (
                    <MenuItem value={org.id} key={org.id}>
                      {org.id}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Select id="organisation-select-label" label="Organization" />
              )}
            </FormControl>
            {selectedOrgID && (
              <Button variant="contained" disabled={!submitEnabled} color="primary" onClick={handleLink} className={classes.button}>
                Link organization to AWS
              </Button>
            )}
          </div>
          <Typography gutterBottom className={classes.content}>
            If you prefer, you can{" "}
            <Link className={classes.link} onClick={handleSwitchUser}>
              switch to a different user
            </Link>
            .
          </Typography>
        </>
      )
    }
  }

  const renderToast = () => {
    return (
      <Snackbar open={toastOpen} autoHideDuration={errorMsg === "" ? 1500 : 5000} onClose={handleToastEnd}>
        {errorMsg === "" ? (
          <Alert onClose={handleToastEnd} severity="success">
            AWS registration successful. Your environment will load in a few seconds.
          </Alert>
        ) : (
          <Alert onClose={handleToastEnd} severity="error">
            AWS registration failed: {errorMsg}
          </Alert>
        )}
      </Snackbar>
    )
  }

  return (
    <div className={classes.root}>
      {isLoading || !user ? (
        <LoadingMessage label="loading" />
      ) : (
        <div className={classes.frame}>
          {customerID === "" ? (
            <Typography gutterBottom className={classes.content}>
              Missing customerID.
            </Typography>
          ) : (
            <>
              <div className={classes.logo}>
                <Logo />
              </div>
              {renderContent()}
            </>
          )}
        </div>
      )}
      {renderToast()}
    </div>
  )
}
