import React from "react"
import { Paper, Link, createStyles, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    section: {
      margin: theme.spacing(2, 0),
      padding: theme.spacing(2.5),
      width: "75vw",
      "& p": {
        margin: theme.spacing(1, 0),
      },
    },
    title: {
      fontSize: "1.5rem",
      marginBottom: theme.spacing(2),
    },
  })
})

type PrivateBetaMsgProps = {
  user: UserType
}

export const PrivateBetaMsg = ({ user }: PrivateBetaMsgProps) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Paper className={classes.section}>
        <h2 className={classes.title}>Private Beta Program</h2>
        <p>{`Hello ${user.name}.`}</p>
        <p>
          Currently, Jetstack Secure is only available to those in the private beta. You are not a member of any of the registered
          organizations.
        </p>
        <p>
          If you are interested you can <Link href="https://jetstack.io/preflight#contactform">apply to join.</Link>
        </p>
      </Paper>
    </div>
  )
}
