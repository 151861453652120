import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import * as queryString from "query-string"

import { createStyles, makeStyles, Theme, Button, Typography } from "@material-ui/core"

import { Logo } from "@components/Logo"

import { AWS_LINK_ORGANIZATION } from "@routes"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      margin: theme.spacing(4),
    },
    frame: {
      padding: theme.spacing(6),
      background: theme.palette.common.black,
      maxWidth: "51rem",
      width: "100%",
      borderRadius: 5,
    },
    logo: {
      maxWidth: "19rem",
      padding: theme.spacing(2, 0, 6, 0),
      display: "inline-block",
    },
    content: {
      color: "#fff",
      fontSize: "1.125rem",
      marginBottom: theme.spacing(2),
    },
    button: {
      fontSize: "1.125rem",
      fontWeight: 500,
      padding: theme.spacing(2, 5),
      lineHeight: 1.4,
      marginRight: theme.spacing(6),

      [theme.breakpoints.down(610)]: {
        width: "100%",
        maxWidth: "20rem",
        margin: theme.spacing(0, 0, 4, 0),
      },
    },
    link: {
      color: "#fff",
      textTransform: "uppercase",
      textDecoration: "underline",
      fontSize: "1rem",
      "&:hover": {
        textDecoration: "none",
      },
    },
    buttons: {
      margin: theme.spacing(4, 0, 2, 0),
      textAlign: "right",
      [theme.breakpoints.down(768)]: {
        marginTop: 0,
      },
    },
  }),
)

export const AWSLanding = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()

  const [customerID, setCustomerID] = useState("")

  // Use customer UI from query
  useEffect(() => {
    const query = queryString.parse(location.search)
    setCustomerID(query.customerID || "")
  }, [history])

  const handleContinue = () => {
    navigate({
      pathname: AWS_LINK_ORGANIZATION,
      search: "?customerID=" + customerID,
    })
  }

  const renderContent = () => {
    return (
      <>
        <Typography gutterBottom className={classes.content}>
          You have purchased a Jetstack Secure license using the AWS Marketplace.
        </Typography>
        <Typography gutterBottom className={classes.content}>
          This last step will link an organization in Jetstack Secure with your AWS billing.
        </Typography>
        <Typography gutterBottom className={classes.content}>
          You will need to create a Jetstack Secure account if you do not already have one.
        </Typography>
        <div className={classes.buttons}>
          <Button variant="contained" color="primary" onClick={handleContinue} className={classes.button}>
            Continue
          </Button>
        </div>
      </>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.frame}>
        {customerID === "" ? (
          <Typography gutterBottom className={classes.content}>
            Missing customerID.
          </Typography>
        ) : (
          <>
            <div className={classes.logo}>
              <Logo />
            </div>
            {renderContent()}
          </>
        )}
      </div>
    </div>
  )
}
