import React, { ReactElement } from "react"
import { SlackIcon, TeamsIcon } from "@components/Icons"
import { Channel } from "./types"

export const getReceiverConfig = (
  type: Channel | undefined,
): {
  icon: ReactElement
  urlExample: string
  docLink: string
  name: string
  label: string
} | null => {
  switch (type) {
    case "slack":
      return {
        icon: <SlackIcon />,
        urlExample: "e.g. https://hooks.slack.com/...",
        docLink: "https://slack.com/apps/new/A0F7XDUAZ-incoming-webhooks",
        name: "Slack",
        label: "Slack hook URL",
      }
    case "microsoft-teams":
      return {
        icon: <TeamsIcon />,
        urlExample: "e.g. https://example.webook.office.com/...",
        docLink: "https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook",
        name: "Microsoft Teams",
        label: "Microsoft Teams hook URL",
      }
    default:
      return null
  }
}

export const getReceiverType = (receiver: Receiver): Channel | undefined => {
  if (receiver.slack_webhook_url) {
    return "slack"
  } else if (receiver.microsoft_teams_webhook_url) {
    return "microsoft-teams"
  } else {
    return undefined
  }
}

export const extractReceiverUrl = (receiver: Receiver): string | undefined => {
  if (receiver.slack_webhook_url) {
    return receiver.slack_webhook_url
  } else if (receiver.microsoft_teams_webhook_url) {
    return receiver.microsoft_teams_webhook_url
  } else {
    return undefined
  }
}
