import React, { useState, useEffect, Dispatch, SetStateAction } from "react"
import { createStyles, makeStyles, Theme, Button, Typography } from "@material-ui/core"
import { clusterExists, nameIsValid } from "../helpers"
import { Snippet } from "@components/Snippet"
import { useFetchClusters } from "@hooks/useFetchClusters"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disabled: {
      pointerEvents: "none",
      opacity: 0.6,
    },
    nameInput: {
      display: "block",
      height: "3rem",
      fontSize: "1.125rem",
      borderRadius: "5px",
      padding: theme.spacing(1, 2),
      border: `1px solid ${theme.palette.grey[400]}`,
      margin: theme.spacing(1, 0),
      width: "16rem",
      "&:disabled": {
        background: theme.palette.grey[100],
      },
    },
    nameError: {
      color: theme.palette.error.main,
    },
    button: {
      marginTop: theme.spacing(2),
    },
    buttonWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
  }),
)

type InstallCLIProps = {
  clusterName: string
  setClusterName: Dispatch<SetStateAction<string>>
  currentStep: string
  setStep: Dispatch<SetStateAction<number>>
}

export const NAME_AND_CONNECT_CLUSTER = "NAME_AND_CONNECT_CLUSTER"

export const NameAndConnectCluster = ({ setStep, currentStep, clusterName, setClusterName }: InstallCLIProps) => {
  const classes = useStyles()

  const isCurrentStep = currentStep === NAME_AND_CONNECT_CLUSTER
  const [nameError, setNameError] = useState("")

  const { clusters } = useFetchClusters()

  const handleClusterNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value
    setClusterName(name)
    setNameError("")
  }

  useEffect(() => {
    if (!clusterName) {
      return
    } else if (clusterExists(clusters, clusterName)) {
      setNameError(`Cluster '${clusterName}' already exists. Choose a different name.`)
    } else if (!nameIsValid(clusterName)) {
      setNameError("The name must contain only lowercase letters, numbers, and underscores.")
    } else {
      setNameError("")
    }
  }, [clusterName, clusters])

  return (
    <div className={!isCurrentStep ? classes.disabled : ""}>
      <Typography variant="h5" gutterBottom>
        Step 3. Name and Connect the Cluster
      </Typography>

      {isCurrentStep && (
        <div>
          <Typography color="textSecondary" gutterBottom>
            First, enter a name for this new cluster
          </Typography>
          <input
            className={classes.nameInput}
            type="text"
            name="Cluster name"
            value={clusterName}
            onChange={handleClusterNameChange}
            placeholder="my_cluster"
          />
          {nameError && <p className={classes.nameError}>{nameError}</p>}

          {!nameError && clusterName !== "" && (
            <>
              <br />
              <Typography color="textSecondary" gutterBottom>
                Next, run the following command to connect the cluster to Jetstack Secure.
              </Typography>
              <Snippet text={`jsctl clusters connect ${clusterName}`} language="shell" />
              <div className={classes.buttonWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={false}
                  onClick={() => {
                    setStep(step => step + 1)
                  }}
                >
                  Next
                </Button>
                <Button variant="contained" color="secondary" disabled={false} onClick={() => setStep(step => step - 1)}>
                  Back
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  )
}
