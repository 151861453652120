import React from "react"
import { createStyles, makeStyles, Theme, Paper, Typography, Link, Button } from "@material-ui/core"
import { useNavigate } from "react-router-dom"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "contents",
      width: "100%",
      height: "100%",
    },
    paper: {
      padding: theme.spacing(4, 4, 6, 4),
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.paper,
      margin: theme.spacing(8, "auto"),
      width: "32rem",
      textAlign: "center",
    },
    button: {
      marginTop: theme.spacing(3),
    },
  }),
)

export function ErrorFallback() {
  const classes = useStyles()
  const navigate = useNavigate()

  const onClick = () => {
    navigate("/")
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography gutterBottom variant="h4" color="error">
          Uncaught error
        </Typography>
        <Typography gutterBottom>
          We're not sure what went wrong. You can try again or{" "}
          <Link href="mailto:jetstack-secure@jetstack.io?subject=Jetstack%20Secure%20Support">email us</Link> describing your issue.{" "}
        </Typography>
        <Button variant="contained" color="primary" className={classes.button} onClick={onClick}>
          Homepage
        </Button>
      </Paper>
    </div>
  )
}
