import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Typography, createStyles, makeStyles, Theme, Button, Paper } from "@material-ui/core"
import axios, { AxiosResponse } from "axios"

import { LoadingMessage } from "@components/LoadingMessage"
import { Table, TableHeader } from "@components/ResourcesTable"
import { accessTokenSelector } from "@selectors/auth"
import { handleError } from "@utils/errorHandling"
import { addAuth } from "@lib/auth"
import { ServiceAccountRow } from "./ServiceAccountRow"
import { CreateModal } from "./CreateModal"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    view: {
      width: "100%",
      height: "100%",
      padding: theme.spacing(4),
      "& h5": {
        marginBottom: theme.spacing(2),
      },
    },
    section: {
      margin: theme.spacing(4, 0),
    },
    manageTitle: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      gridGap: "1rem",
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    dialog: {
      padding: theme.spacing(4),
      position: "relative",
    },
    privateImageInput: {
      minWidth: "100%",
      margin: theme.spacing(2, 0, 3, 0),
    },
    error: {
      color: theme.palette.error.main,
    },
    closeBtn: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
      fontSize: "2.4rem",
      color: theme.palette.primary.main,
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.error.main,
      },
    },
  }),
)

export type ServiceAccount = {
  id: string
  displayName: string
}

type PrivateImagesViewProps = {
  orgID: string
}

const PrivateImagesView = ({ orgID }: PrivateImagesViewProps) => {
  const classes = useStyles()
  const token = useSelector(accessTokenSelector)
  const [isLoading, setIsLoading] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [serviceAccounts, setServiceAccounts] = useState<ServiceAccount[]>([])

  useEffect(() => {
    getServiceAccounts()
  }, [])

  const getServiceAccounts = async () => {
    setIsLoading(true)

    try {
      const { data } = await axios.get<unknown, AxiosResponse<ServiceAccount[]>>(
        `/subscription/api/v1/org/${orgID}/svc_accounts`,
        addAuth(token),
      )
      setServiceAccounts(data)
    } catch (error) {
      handleError(error, "Failed to fetch image pull secrets")
    } finally {
      setIsLoading(false)
    }
  }

  const deleteServiceAccount = async (id: string) => {
    setIsLoading(true)

    try {
      await axios.delete<{ id: string }, AxiosResponse<ServiceAccount[]>>(
        `/subscription/api/v1/org/${orgID}/svc_accounts`,
        addAuth(token, { data: [{ id }] }),
      )
      await getServiceAccounts()
    } catch (error) {
      handleError(error, "Failed to fetch image pull secrets")
    } finally {
      setIsLoading(false)
    }
  }

  const onDelete = async (id: string) => {
    setIsLoading(true)
    deleteServiceAccount(id)
  }

  return (
    <>
      <Paper className={classes.view}>
        <Typography gutterBottom variant="h4">
          Accessing our private image registry from your cluster
        </Typography>
        <Typography gutterBottom>
          Your Jetstack Secure subscription provides access to closed-source and FIPS 140-2 compliant container images. On this page you can
          generate image pull secrets to access our private container image registry.
        </Typography>
        <div className={classes.section}>
          <Typography gutterBottom variant="h5" className={classes.manageTitle}>
            Image pull secrets
            <Button color="primary" variant="contained" onClick={() => setIsModalOpen(true)}>
              Create image pull secret
            </Button>
          </Typography>
        </div>
        {isLoading ? (
          <LoadingMessage label="loading data" />
        ) : (
          <Table>
            <TableHeader rows={[{ label: "Image pull secrets" }, { label: "", size: 10 }]} />
            <tbody>
              {serviceAccounts.map(serviceAccount => (
                <ServiceAccountRow serviceAccount={serviceAccount} onDelete={onDelete} key={serviceAccount.id} />
              ))}
            </tbody>
          </Table>
        )}
      </Paper>
      <CreateModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} orgID={orgID} token={token} reload={getServiceAccounts} />
    </>
  )
}

export { PrivateImagesView }
