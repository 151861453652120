import React, { Dispatch, SetStateAction, useState } from "react"
import { Button, Typography, createStyles, makeStyles, RadioGroup, FormControlLabel, Radio, FormControl } from "@material-ui/core"
import { Snippet } from "@components/Snippet"

export const INSTALL_CLI = "INSTALL_CLI"

type InstallCLIProps = {
  currentStep: string
  setStep: Dispatch<SetStateAction<number>>
}

const useStyles = makeStyles(() =>
  createStyles({
    disabled: {
      pointerEvents: "none",
      opacity: 0.6,
    },
  }),
)

export const InstallCLI = ({ setStep, currentStep }: InstallCLIProps) => {
  const isCurrentStep = currentStep === INSTALL_CLI
  const cliVersion = "0.1.15"
  const classes = useStyles()

  const [operatingSystem, setOperatingSystem] = useState("unix")

  return (
    <div className={!isCurrentStep ? classes.disabled : ""}>
      <Typography variant="h5" gutterBottom>
        Step 1. Install the CLI
      </Typography>

      {isCurrentStep && (
        <div>
          <Typography color="textSecondary" gutterBottom>
            Before anything you need to install the CLI tool for Jetstack Secure, <code>jsctl</code>. If you are already familiar with&ensp;
            <code>jsctl</code> then you can skip this step by clicking 'Next'.
          </Typography>

          <FormControl>
            <RadioGroup
              defaultValue={operatingSystem}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setOperatingSystem((event.target as HTMLInputElement).value)
              }}
            >
              <FormControlLabel value="unix" control={<Radio />} label="Linux, macOS, or Windows Subsystem for Linux (WSL)" />
              <FormControlLabel value="windows" control={<Radio />} label="Microsoft Windows" />
            </RadioGroup>
          </FormControl>

          <br />
          {operatingSystem === "unix" ? (
            <div>
              <Typography color="textSecondary" gutterBottom>
                Run the following commands to download and install a suitable build of <code>jsctl</code> for your operating system.
                Alternatively, you can visit the{" "}
                <a target="_blank" rel="noopener noreferrer" href={`https://github.com/jetstack/jsctl/releases/tag/v${cliVersion}`}>
                  GitHub releases page
                </a>
                &ensp; and perform the installation yourself.
              </Typography>
              <Snippet
                copyText={`VERSION="${cliVersion}"
ASSET_REF="jsctl-$VERSION-$(uname -s)-$(uname -m)"
ARCHIVE="$ASSET_REF.tar.gz"
curl -LO https://github.com/jetstack/jsctl/releases/download/v$VERSION/$ARCHIVE
tar -zxvf $ARCHIVE
sudo mv ./$ASSET_REF/jsctl /usr/local/bin/jsctl`}
                text={`VERSION="${cliVersion}"
$ ASSET_REF="jsctl-$VERSION-$(uname -s)-$(uname -m)"
$ ARCHIVE="$ASSET_REF.tar.gz"
$ curl -LO https://github.com/jetstack/jsctl/releases/download/v$VERSION/$ARCHIVE
$ tar -zxvf $ARCHIVE
$ sudo mv ./$ASSET_REF/jsctl /usr/local/bin/jsctl`}
                language="shell"
              />
            </div>
          ) : (
            <div>
              <Typography color="textSecondary" gutterBottom>
                Please download and install the latest version of jsctl from the&ensp;
                <a target="_blank" rel="noopener noreferrer" href={`https://github.com/jetstack/jsctl/releases/tag/v${cliVersion}`}>
                  GitHub releases page
                </a>
                .
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                Once you have installed <code>jsctl</code>, check that you can run it and confirm the version is {cliVersion}
              </Typography>
              <Snippet copyText={`jsctl version`} text={`jsctl version`} language="shell" />
            </div>
          )}

          <Button variant="contained" color="primary" onClick={() => setStep(step => step + 1)}>
            Next
          </Button>
        </div>
      )}
    </div>
  )
}
