import * as Sentry from "@sentry/react"

export function handleError(error: Error | unknown, message?: string) {
  console.error(message, error)
  Sentry.withScope(scope => {
    message && scope.setExtra("message", message)
    Sentry.captureException(error)
  })
}

export function generateErrorMessage(error: unknown): string {
  return error instanceof Error ? error.message : "An unknown error has occurred."
}
